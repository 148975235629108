import React, { Component } from "react";
import FooterFirst from "./Footer/FooterFirst";
import FooterTwo from "./Footer/FooterTwo";

class Footer extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=58418355-ac81-4999-9f6c-218ed885029c";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div>
        <footer id="footer" className="footer-texts-more-lighten">
          <FooterFirst />
          <FooterTwo />
        </footer>
        {/* Start of creationist Zendesk Widget script */}{" "}
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=58418355-ac81-4999-9f6c-218ed885029c"
        >
          {" "}
        </script>{" "}
        {/* <!-- End of creationist Zendesk Widget script -->*/}
      </div>
    );
  }
}

export default Footer;
