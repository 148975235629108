import React, { Component } from 'react'
import cf from '../../global/CommonFunctions';
import axios from "axios";
import { NavLink } from "react-router-dom";

export default class Mobile_Computer3 extends Component {

    constructor(props) {
       
        super(props)
        this.state = {
            ourProductCrouselList: []
            
    
    
    
        }
        this.tags = cf.getTags();
    }

    componentDidMount() {
    
        this.ourProductCrousel();
        
      
      }

      ourProductCrousel() {
        this.setState({loading: true})
        let params = {
          
            "category": "bath-body",  
                "facet": true,
                
                "limit": 4,
                "pageNumber": 1,
                "price": [],
                "sortBy": {"price": 0},
                "source": "web",
                "subcat": "bath-body",
                "type": 1
        }
          
        
        axios.post(process.env.REACT_APP_NODE_API_URL.trim() + "/shopperrb2b/solr/searchProducts", params)
            .then(res => {
                console.log(res.data.Response.data.products)
                this.setState({ ourProductCrouselList: res.data.Response.data.products })
                 
            })
      }
  render() {
    return (
      <>
      <div className='container' style={{marginTop:'0px'}}>
      <div className='row'>
      
         
                    
      
          <div className='col-lg-3 sc-gWXbKe hoZTIr __pf '>
          <div data-pf-type="Column" className="sc-hAcGzb bAQARH pf-92_comp">
          <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-93_">
              <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-95_">
             WireLess <br />
              Speaker
              </span>
          </h3>
          <a href="/computers-accessories" data-pf-type="Button" className="sc-dtMgUX gJMWBM pf-96_">
              <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-98_">
              View More
              </span>
          </a>
          </div>
          </div>
          <div className='col-lg-9 row ' style={{marginTop:'40px'}}>
          {
          this.state.ourProductCrouselList?.map((item, index) => {
            
            return ( 
      <div className='col-sm-6 col-lg-3'>
          <div className="product mb-0">
              <div className="product-thumb-info border-0 mb-3">
              <div className="product-thumb-info-badges-wrapper">
                  <span className="badge badge-ecommerce badge-success">exclusive</span>
              </div>
              <NavLink className="product-desc text-color-dark text-color-hover-primary"
                      to={'/product/' + item.handle}
                      exact
                    >
                  <div className="product-thumb-info-image">
                  <span
                      className=" lazy-load-image-background  lazy-load-image-loaded"
                      style={{ display: "inline-block", height: 250, width: 250 }}
                  >
                      <img
                      height="250px"
                      width="250px"
                      src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""}
                      />
                  </span>
                  </div>
                  </NavLink>
              </div>
              <div className="d-flex justify-content-between">
              <div>
                  <a
                  className="d-block text-uppercase  text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                  href="/undefined"
                  />
                  <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                  <NavLink
                          className="product-desc text-color-dark text-color-hover-primary"
                          to={'/product/' + item.handle}
                          exact
                        >
                          {item.title}
                        </NavLink>
                  </h3>
              </div>
              <a
                  className="text-decoration-none text-color-default text-color-hover-dark text-4"
                  style={{ display: "none" }}
              >
                  <i className="far fa-heart" />
              </a>
              </div>
              
              <p className="price text-5 mb-3">
              <span className="sale text-color-dark font-weight-semi-bold">{cf.getPriceWithCurrency(new Number(item.displayPrice).toFixed(2))}</span>
              <span className="amount">{cf.getPriceWithCurrency(new Number(item.recommendedRetailPrice).toFixed(2))}</span>
              </p>
          </div>
          </div>
          )
      })
       
    } 

          </div>
      </div>
  </div>
      </>
    )
  }
}
