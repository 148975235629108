import React from 'react'
import Mobile_Computer1 from './Mobile_Computer1'
import Mobile_Computer2 from './Mobile_Computer2'
import Mobile_Computer3 from './Mobile_Computer3'
import Mobile_Computer4 from './Mobile_Computer4'
export default function Mobile_Computer() {
  return (
    <>
        <Mobile_Computer1/>
        <Mobile_Computer2/>
        <Mobile_Computer3/>
        <Mobile_Computer4/>
    </>
  )
}
