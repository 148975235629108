import axios from "axios";
class CommonFunctions {
  constructor() {
    this.specialCharRegex = /[^a-z0-9]/;
  }
  getValidURL(keyword = "") {
    return keyword
      .toLowerCase()
      .replace(new RegExp(this.specialCharRegex, "gi"), "-")
      .replace(/-{2,}/g, "-")
      .trim("-");
  }
  getTags() {
    return [
      "collectibles",
      "motor-part",
      "car-emplifier",
      "dongguan-langzu",
      "guangzhou-kuer",
      "all-electronics",
      "lelisten",
      "all-mobile-accessories",
      "electronic-appliances",
      "headphones",
      "mobiles-computers",
      "shantou-lexi",
      "wearable-devices",
      "yongkang-tuyi",
      "bluetooth-digital-scale",
      "magic",
      "xxuzhou-rich",
      "all-electronics",
      "car-mobile-holder",
      "car-stereo",
      "computer-accessories",
      "esunway-shenzhen",
      "guangzhou-princess",
      "hdmi-series",
      "health-personal-care",
      "le-listen",
      "motor-parts",
      "newproducts",
      "shenzhen-casdte",
      "shenzhen-cregift",
      "shenzhen-lexin",
      "shenzhen-magelei",
      "shenzhen-qiwan",
      "shenzhen-vanedove",
      "tws-earphone",
      "weighing-scale",
      "woyaqi-shenzhen",
      "xiamen-koderay",
      "yuantu-culture",
      "all-computers-&-accessories",
      "new",
      "aogfy",
      "bathroommirror",
      "carseatcushion",
      "cartoonearphone",
      "casun",
      "chestcompartments",
      "clothing",
      "cutlerytray",
      "cuttingboard",
      "drawerorganizer",
      "eva-mat",
      "earphone",
      "extralargebamboocuttingboard",
      "eyelashcase",
      "eyelashholder",
      "fastcharging",
      "floorboughpot",
      "foldable-mirror",
      "footwearexclusive",
      "gdyayu",
      "galova",
      "gifts&toysexclusive",
      "glass",
      "greenfilm",
      "greenport",
      "home&kitchen",
      "jacotta",
      "kms",
      "kids",
      "knifeblock",
      "led",
      "ledlamp",
      "ledmakeupmirror",
      "ledmirror",
    ];
  }
  getPriceWithCurrency(price) {
    return "₹ " + price;
  }
  setCallBack(callback) {
    this.callback = callback;
  }
  getCallBack() {
    return this.callback;
  }
}

const commonFunObj = new CommonFunctions();
export default commonFunObj;
