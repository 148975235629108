import React, { Component } from "react";
import imgone from "../img/diwaliOne.jpg";
import imgtwo from "../img/diwaliTwo.jpg";
import imgthree from "../img/diwaliThree.jpg";

class ImageCrousel extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div
            className="owl-carousel owl-theme nav-inside owl-loaded owl-drag owl-carousel-init"
            data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false}"
            style={{ height: "auto" }}
          >
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-1126px, 0px, 0px)",
                  transition: "all 0.25s ease 0s",
                  width: "2252px",
                }}
              >
                <div
                  className="owl-item"
                  style={{ width: "1116px", marginRight: "10px" }}
                >
                  <div>
                    <div className="img-thumbnail border-0 p-0 d-block">
                      <img
                        className="img-fluid border-radius-0"
                        src={imgone}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: "1116px", marginRight: "10px" }}
                >
                  <div>
                    <div className="img-thumbnail border-0 p-0 d-block">
                      <img
                        className="img-fluid border-radius-0"
                        src={imgtwo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: "1116px", marginRight: "10px" }}
                >
                  <div>
                    <div className="img-thumbnail border-0 p-0 d-block">
                      <img
                        className="img-fluid border-radius-0"
                        src={imgthree}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav">
              <button
                type="button"
                role="presentation"
                className="owl-prev"
              ></button>
              <button
                type="button"
                role="presentation"
                className="owl-next disabled"
              ></button>
            </div>
            <div className="owl-dots disabled"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCrousel;
