import React, { Component } from "react";
import MetaTags from "react-meta-tags";

class Contactus extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      customer: {
        fullName: props.fullName,
        email: props.email,
        subject: props.subject,
        message: props.message,
      },
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("https://sellercentral-staging.shopperr.in/sscapi/mobile/lead", {
      method: "POST",
      body: data,
    });
  }

  handleFullNameChanged(event) {
    var customer = this.state.customer;
    customer.fullName = event.target.value;

    this.setState({ customer: customer });
  }

  handleemailChanged(event) {
    var customer = this.state.customer;
    customer.email = event.target.value;

    this.setState({ customer: customer });
  }

  handlesubjectChanged(event) {
    var customer = this.state.customer;
    customer.subject = event.target.value;

    this.setState({ customer: customer });
  }

  handlemessageChanged(event) {
    var customer = this.state.customer;
    customer.message = event.target.value;

    this.setState({ customer: customer });
  }

  handleButtonClicked() {
    console.log(this.state.customer);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div class="wrapper">
        <MetaTags>
          <title>Contact Us</title>
          <description>
            Shopperr is one of the most evolved B2B platform that enables
            members to source products of international brands directly from
            manufacturers and suppliers.
          </description>
          {/* <meta id="description" name="mg-description" content="About Us In Shopperr." />
                    <meta id="keywords" property="mg-keyword" content="head,body,html,tags" /> */}
        </MetaTags>
        <div role="main" className="main">
          {/* Google Maps - Go to the bottom of the page to change settings and map location. */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.3962382383356!2d77.09157411481935!3d28.46761138248305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d0d6697e7d%3A0x54ad3e62ca712477!2s1sp%2C%20Golf%20Course%20Rd%2C%20Sector%2027%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1664446173292!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="container">
            <div className="row py-4">
              <div className="col-lg-6">
                <h2 className="font-weight-bold text-8 mt-2 mb-0">
                  Contact Us
                </h2>
                <p className="mb-4">
                  Feel free to ask for details, don't save any questions!
                </p>
                <form onSubmit={this.handleSubmit}>
                  <div className="contact-form-success alert alert-success d-none mt-4">
                    <strong>Success!</strong> Your message has been sent to us.
                  </div>
                  <div className="contact-form-error alert alert-danger d-none mt-4">
                    <strong>Error!</strong> There was an error sending your
                    message.
                    <span className="mail-error-message text-1 d-block" />
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-label mb-1 text-2">
                        Full Name
                      </label>
                      <input
                        type="text"
                        data-msg-required="Please enter your name."
                        maxLength={100}
                        className="form-control text-3 h-auto py-2"
                        name="name"
                        value={this.state.customer.firstName}
                        onChange={this.handleFullNameChanged.bind(this)}
                        required
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="form-label mb-1 text-2">
                        Email Address
                      </label>
                      <input
                        type="email"
                        data-msg-required="Please enter your email address."
                        data-msg-email="Please enter a valid email address."
                        maxLength={100}
                        className="form-control text-3 h-auto py-2"
                        value={this.state.customer.email}
                        onChange={this.handleemailChanged.bind(this)}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label className="form-label mb-1 text-2">Subject</label>
                      <input
                        type="text"
                        data-msg-required="Please enter the subject."
                        maxLength={100}
                        className="form-control text-3 h-auto py-2"
                        value={this.state.customer.subject}
                        onChange={this.handlesubjectChanged.bind(this)}
                        name="subject"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label className="form-label mb-1 text-2">Message</label>
                      <textarea
                        maxLength={5000}
                        data-msg-required="Please enter your message."
                        rows={8}
                        className="form-control text-3 h-auto py-2"
                        name="message"
                        value={this.state.customer.message}
                        onChange={this.handlemessageChanged.bind(this)}
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <input
                        type="submit"
                        onClick={this.handleButtonClicked.bind(this)}
                        defaultValue="Send Message"
                        className="btn btn-primary btn-modern"
                        data-loading-text="Loading..."
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div
                  className="appear-animation animated fadeIn appear-animation-visible"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay={800}
                  style={{ animationDelay: "800ms" }}
                >
                  <h4 className="mt-2 mb-1">
                    Our <strong>Office</strong>
                  </h4>
                  <ul className="list list-icons list-icons-style-2 mt-2">
                    <li>
                      <i className="fas fa-map-marker-alt top-6" />{" "}
                      <strong className="text-dark">Address:</strong> One Stop
                      Fashions Pvt. Ltd.1sp, Golf Course Rd, Sector 27,
                      Gurugram, Haryana 122009 Gurugram, HR, IN,India
                    </li>
                    <li>
                      <i className="fas fa-phone top-6" />{" "}
                      <strong className="text-dark">Phone:</strong> (123)
                      456-789
                    </li>
                    <li>
                      <i className="fas fa-envelope top-6" />{" "}
                      <strong className="text-dark">Email:</strong>{" "}
                      <a href="mailto:mail@example.com">support@shopperr.in</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="appear-animation animated fadeIn appear-animation-visible"
                  data-appear-animation="fadeIn"
                  data-appear-animation-delay={950}
                  style={{ animationDelay: "950ms" }}
                >
                  <h4 className="pt-5">
                    Business <strong>Hours</strong>
                  </h4>
                  <ul className="list list-icons list-dark mt-2">
                    <li>
                      <i className="far fa-clock top-6" /> Monday - Friday - 9am
                      to 5pm
                    </li>
                    <li>
                      <i className="far fa-clock top-6" /> Saturday - 9am to 2pm
                    </li>
                    <li>
                      <i className="far fa-clock top-6" /> Sunday - Closed
                    </li>
                  </ul>
                </div>
                <h4 className="pt-5">
                  Get in <strong>Touch</strong>
                </h4>
                <p className="lead mb-0 text-4">
                  Shopperr is a marketplace that makes it easy to find awesome
                  products to sell online. Shopperr is a marketplace that makes
                  it easy to find awesome products to sell online.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contactus;
