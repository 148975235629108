import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

class MultiProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsCard: [],
      keyword: props.keyword ?? "footwear",
      headingText: props.headingText ?? "New Products",
      urlText: props.urlText ?? "footwear",
    };
  }
  componentDidMount() {
    this.MultiproductCards();
  }

  MultiproductCards() {
    let params = {
      keyword: this.state.keyword,
      type: 1,
      limit: 4,
      pageNumber: 1,
      source: "web",
    };

    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/shopperrb2b/solr/toprated",
        params
      )
      .then((res) => {
        this.setState({ productsCard: res.data.Response.data.products });
      });
  }

  render() {
    return (
      <div
        className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
        data-appear-animation="fadeInUpShorter"
        data-appear-animation-delay={400}
        style={{ animationDelay: "400ms" }}
      >
        <div
          className="card border-radius-0 bg-color-light border-0 box-shadow-1"
          style={{ marginBottom: "20px" }}
        >
          <div className="card-body">
            <h4
              className="card-title mb-1 text-4 font-weight-bold"
              style={{ textAlign: "left" }}
            >
              {this.state.headingText}
            </h4>
            <div className="row" style={{ marginTop: "25px" }}>
              {this.state.productsCard
                ? this.state.productsCard.map((item, index) => {
                    let discount = (item.sellingPrice * 18) / 100;
                    discount = discount ? discount : 0;
                    return (
                      <div className="col-lg-6 mb-2" key={index}>
                        <NavLink
                          className="product-desc text-color-dark text-color-hover-primary fullLength"
                          to={"/product/" + item.url}
                          exact
                        >
                          <img
                            className="img-fluid rounded mb-2"
                            src={
                              item.imgUrl && item.imgUrl.length
                                ? item.imgUrl[0].src
                                : ""
                            }
                            alt="Electronics"
                          />
                        </NavLink>

                        <p className="price text-4 mb-0">
                          <span className="sale text-color-dark font-weight-semi-bold">
                            ₹ {item.displayPrice}{" "}
                          </span>
                          <span className="amount">
                            ₹ {item.recommendedRetailPrice}
                          </span>
                        </p>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div
              style={{ position: "absolute", bottom: "10px", width: "100%" }}
            >
              <a href={"/" + this.state.urlText} style={{ color: "#ffa000" }}>
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultiProductCard;
