import React, { Component } from "react";
import cf from "../../global/CommonFunctions";

export default class OrderNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ order: nextProps.order });
  }
  render() {
    let date = new Date(this.state.order?.customer?.updated_on ?? new Date());
    return (
      <div className="d-flex flex-column flex-md-row justify-content-between py-3 px-4 my-4">
        <div className="text-center">
          <span>
            Order Number <br />
            <strong className="text-color-dark">
              {this.state.order?.orderId}
            </strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Date <br />
            <strong className="text-color-dark">
              {this.state.months[date.getMonth()] +
                " " +
                date.getDay() +
                "," +
                date.getFullYear()}
            </strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Email <br />
            <strong className="text-color-dark">
              {this.state.order?.customer?.email}
            </strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Total <br />
            <strong className="text-color-dark">
              {cf.getPriceWithCurrency(this.state.order?.customer?.price ?? 0)}
            </strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Payment Method <br />
            <strong className="text-color-dark">Cash Free</strong>
          </span>
        </div>
      </div>
    );
  }
}
