import axios from "axios";
class User {
  tokenkey = Symbol(process.env.REACT_APP_USER_TOKEN_KEY.trim());
  constructor() {}
  setUser(data) {
    return window.localStorage.setItem(this.tokenkey.description, data);
  }
  getUser() {
    return (
      JSON.parse(window.localStorage.getItem(this.tokenkey.description)) || null
    );
  }
  logoutUser() {
    return window.localStorage.removeItem(this.tokenkey.description);
  }
  loginUser() {
    localStorage.setItem("lref", window.location.href);
    window.location.href = `${process.env.REACT_APP_NODE_API_URL}/login-merchant?redirecturl=${window.origin}/verify-login-merchant`;
  }
}

const userObj = new User();
export default userObj;
