import React, { Component } from "react";
import cf from "../../global/CommonFunctions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeHolderSrc from "../../img/placeholder-medium.png";
import User from "../../global/User";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

export default class CartProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      cart: {},
      cartDetail: { grossAmount: 0 },
    };
    this.updateCart = this.updateCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.updateQu = this.updateQu.bind(this);
  }
  componentDidMount() {
    this.orderDetails();
  }
  orderDetails() {
    let user = User.getUser();
    if (!user) return User.loginUser();
    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/sscapi/web/addtocart",
        {
          key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
          token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
          merchantId: user.id,
          source: "web",
          accessStatus: 0,
        }
      )
      .then((res) => {
        let cartDetail = res.data.Response.data;

        //fire Event
        let event = new CustomEvent("updateCart", {
          detail: { ...cartDetail },
        });
        document.dispatchEvent(event);

        let cart = {};
        cartDetail.products.forEach((el) => {
          cart[el.code] = {
            productId: el._id,
            sku: el.code,
            quantity: el.cartQuantity,
          };
        });
        this.setState({ cart });
        this.setState({ products: cartDetail.products });
        delete cartDetail.products;
        this.setState({ cartDetail });
        this.props.parentCallback({ cartDetail });
      })
      .catch((err) => {
       
      });
  }

  updateQu(id, qu) {
    if (!this.state.cart[id]) return;
    if (qu == "+") {
      qu = this.state.cart[id]["quantity"] + 1;
    }
    if (qu == "-") {
      qu = this.state.cart[id]["quantity"] - 1;
    }
    this.state.cart[id]["quantity"] = qu > 0 ? qu : 1;
    this.setState({ cart: this.state.cart });
    // if(this.state.cart[id]['quantity'] > 1)
    //     this.updateCart()
  }
  updateCart() {
    this.addToCartApi(
      Object.values(this.state.cart),
      { accessStatus: 1 },
      this.orderDetails.bind(this)
    );
  }

  removeFromCart = (_id, sku) => {
    this.addToCartApi(
      [
        {
          productId: _id,
          sku: sku,
          quantity: 0,
        },
      ],
      { accessStatus: 1, add: 0 },
      this.orderDetails.bind(this)
    );
  };
  addToCartApi = (cart, params = { accessStatus: 1, add: 0 }, callback) => {
    let user = User.getUser();
    if (!user) return User.loginUser();
    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/sscapi/web/addtocart",
        {
          key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
          token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
          merchantId: user.id,
          source: "web",
          ...params,
          cartData: cart,
        }
      )
      .then((res) => {
        callback();
        toast.success("Your Cart Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        let errObj = error.response.data.Response?.data?.find((el) => el.error);
        let mes = errObj?.msg || error.response.data.Response.msg;
        return toast.error(mes, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    return (
      <div className="col-lg-8 mb-5 mb-lg-0">
        <form method="post">
          <div className="table-responsive">
            <table className="shop_table cart">
              <thead>
                <tr className="text-color-dark">
                  <th className="product-thumbnail" width="15%">
                    &nbsp;
                  </th>
                  <th className="product-name text-uppercase" width="30%">
                    Product
                  </th>
                  <th className="product-price text-uppercase" width="15%">
                    Price
                  </th>
                  <th className="product-quantity text-uppercase" width="20%">
                    Quantity
                  </th>
                  <th
                    className="product-subtotal text-uppercase text-end"
                    width="20%"
                  >
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.products &&
                  this.state.products.map((el, i) => {
                    return (
                      <tr className="cart_table_item" key={i}>
                        <td className="product-thumbnail">
                          <div className="product-thumbnail-wrapper">
                            <a
                              href="#!"
                              className="product-thumbnail-remove"
                              title="Remove Product"
                              onClick={() => {
                                this.removeFromCart(el._id, el.code);
                              }}
                            >
                              <i className="fas fa-times" />
                            </a>
                            <a
                              href="shop-product-sidebar-right.html"
                              className="product-thumbnail-image"
                              title={el.name}
                            >
                              <LazyLoadImage
                                //  alt={item.title}
                                height={"90px"}
                                width={"90px"}
                                src={
                                  el.imgUrl && el.imgUrl.length
                                    ? el.imgUrl[0].src
                                    : ""
                                } // use normal <img> attributes as props
                                onError={(e) => {
                                  e.target.src = placeHolderSrc;
                                }}
                                placeholderSrc={placeHolderSrc}
                              />
                            </a>
                          </div>
                        </td>
                        <td className="product-name">
                          <NavLink
                            className="font-weight-semi-bold text-color-dark text-color-hover-primary text-decoration-none"
                            to={"/product/" + el.handle}
                            exact
                          >
                            {el.name}
                          </NavLink>
                        </td>
                        <td className="product-price">
                          <span className="amount font-weight-medium text-color-grey">
                            {cf.getPriceWithCurrency(el.price)}
                          </span>
                        </td>
                        <td className="product-quantity">
                          <div className="quantity float-none m-0">
                            <input
                              type="button"
                              className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
                              defaultValue="-"
                              onClick={(e) => {
                                this.updateQu(el.code, "-");
                              }}
                            />
                            <input
                              type="text"
                              className="input-text qty text"
                              title="Qty"
                              defaultValue={el.cartQuantity}
                              name="quantity"
                              min={1}
                              step={1}
                              onInput={(e) => {
                                this.updateQu(el.code, e.target.value);
                              }}
                            />
                            <input
                              type="button"
                              className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
                              defaultValue="+"
                              onClick={(e) => {
                                this.updateQu(el.code, "+");
                              }}
                            />
                          </div>
                        </td>
                        <td className="product-subtotal text-end">
                          <span className="amount text-color-dark font-weight-bold text-4">
                            {cf.getPriceWithCurrency(el.price)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}

                <tr>
                  <td colSpan={5}>
                    <div className="row justify-content-between mx-0">
                      <div className="col-md-auto px-0 mb-3 mb-md-0">
                        {/* <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        className="form-control h-auto border-radius-0 line-height-1 py-3"
                                                        name="couponCode"
                                                        placeholder="Coupon Code"
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 ws-nowrap btn-px-4 py-3 ms-2"
                                                    >
                                                        Apply Coupon
                                                    </button>
                                                </div> */}
                      </div>
                      {parseInt(this.state.cartDetail.grossAmount) > 0 && (
                        <div className="col-md-auto px-0">
                          <button
                            type="button"
                            className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 btn-px-4 py-3"
                            onClick={this.updateCart}
                          >
                            Update Cart
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    );
  }
}
