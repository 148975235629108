import React, { Component } from "react";
import axios from "axios";
import imgHomeKitchen from "../img/home_kitchen.jpg";
class SingleImageCard extends Component {
  render() {
    return (
      <div
        className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
        data-appear-animation="fadeInUpShorter"
        data-appear-animation-delay={400}
        style={{ animationDelay: "400ms" }}
      >
        <div
          className="card border-radius-0 bg-color-light border-0 box-shadow-1"
          style={{  marginBottom: "20px" }}
        >
          <div className="card-body">
            <h4
              className="card-title mb-1 text-4 font-weight-bold"
              style={{ textAlign: "left" }}
            >
              Revamp your home in style
            </h4>
            <div className="row" style={{ marginTop: "25px" }}>
              <div className="col-lg-12">
                <a href="/home-kitchen">
                  <img
                    className="img-fluid rounded mb-4"
                    src={imgHomeKitchen}
                    alt="Footwear"
                  />
                </a>
              </div>
            </div>
            <div style={{ position: "absolute", width: "100%" }}>
              <a
                href="/home-kitchen"
                className="read-more text-color-primary"
                style={{ color: "#ffa000", fontSize: "14px" }}
              >
                Explore Now{" "}
                <i className="fas fa-angle-right position-relative top-1 ms-1"></i>
              </a>
            </div>
            {/* <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rhoncus nulla dui, in dapi.</p> */}
          </div>
        </div>
      </div>
    );
  }
}

export default SingleImageCard;
