import React, { Component } from "react";
import Header from "../components/Header.js";
import ImageCrousel from "../components/ImageCrousel.js";
import HomePageSlider from "../components/HomePageSlider.jsx";
import MultiCategoryCard from "../components/MultiCategoryCard.js";
import SingleImageCard from "../components/SingleImageCard.js";
import MultiProductCard from "../components/MultiProductCard.js";
import HomePageProducts from "../components/HomePageProducts.js";
import ProductCrousel from "../components/ProductCrousel.js";
import FeaturedProducts from "../components/FeaturedProducts.js";
import FeaturedNewProducts from "../components/FeaturedNewProducts.js";
import Footer from "../components/Footer.js";
import MetaTags from "react-meta-tags";
import Products from "../components/Products";

export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      tags: {},
      cats: {},
      price: [],
    };
  }
  componentDidMount() {}
  callbackFunction(state = {}) {
    this.setState(state);
  }
  render() {
    return (
      <div>
        <div className="wrapper">
          <MetaTags>
            <title>
              India Dropshipping Supplier &amp; Wholesaler | Shopperr
            </title>
            {/* <description>Shopperr, the leading dropshipping supplier in India-Discover our large catalogue of wholesale products,start selling now without inventory. Free Shipping &amp; No MOQ."</description>
                        <keyword>dropship india dropshipper</keyword> */}
            <meta
              id="description"
              name="mg-description"
              content="Shopperr, the leading dropshipping supplier in India-Discover our large catalogue of wholesale products,start selling now without inventory. Free Shipping &amp; No MOQ."
            />
            <meta
              id="keywords"
              property="mg-keyword"
              content="dropship india dropshipper"
            />
          </MetaTags>
          <div
            className="body wrapping" >
            <Header />
				<HomePageSlider/>
            <div className="container">
              <div
                className="row mb-5 pb-3"
                style={{ position: "relative", zIndex: "1", padding: "20px" }}
              >
               {/* <MultiCategoryCard />  */}
                 {/* <SingleImageCard />  */}
                 {/* <MultiProductCard
                  headingText={"Trending Products"}
                  keyword={"Footwear"}
                  urlText={"footwear"}
                /> */}
              <div style={{ marginTop: "40px" }}>
                <div className="products row">
                  <FeaturedNewProducts
                    headingText={"New Arrivals"}
                    keyword={"Mobile Accessories"}
                  />
                  </div>
                  </div>
                  <br/>
                  <div style={{ marginTop: "40px" }}>
                <div className="products row">
                  <FeaturedNewProducts
                    headingText={"Deals"}
                    keyword={"mobile, clip, toe nail"}
                  />
                  </div>
                  </div>
                  <br/>
                  <div style={{ marginTop: "40px" }}>
                <div className="products row">
                  <FeaturedNewProducts
                    headingText={"Quick Delivery"}
                    keyword={"pencil, car, blade, rail,phone"}
                  />
                  </div>
                  </div>
                  <br/>
                  <div style={{ marginTop: "40px" }}>
                <div className="products row">
                  <FeaturedNewProducts
                    headingText={"Home & Garden"}
                    keyword={"Home & Kitchen,blade,fixture"}
                  />
                  </div>
                  </div>
                  <br/>
                 <MultiProductCard
                   headingText={"Top Picks"}
                 keyword={"Mobile Accessories"}
                   urlText={"mobile-accessories"}
                 />
                <MultiProductCard
                  headingText={"Electronics"}
                  keyword={"electronics, headset,electronics,mobile, clip"}
                  urlText={"computer-peripherals"}
                />
                <MultiProductCard
                  headingText={"Featured Product"}
                  keyword={"Beauty, rail,phone, car"}
                  urlText={"beauty"}
                />
                <MultiProductCard
                  headingText={"Accessories"}
                  keyword={"adhesive socket, shark, headset"}
                  urlText={"gifts-toys"}
                />
                <MultiProductCard
                  headingText={"Luggage & Travel"}
                  keyword={"hanger, wireless copy remote, headset, hair spray"}
                  urlText={"luggage-travel"}
                />
                <MultiProductCard
                  headingText={"Home & Kitchen"}
                  keyword={"Home & Kitchen"}
                  urlText={"home-kitchen"}
                />
                <MultiProductCard
                  headingText={"Photography Accessories"}
                  keyword={"fixture "}
                  urlText={"photography-accessories"}
                />
                <MultiProductCard
                  headingText={"Automobile Accessories"}
                  keyword={"Business & Industrial "}
                  urlText={"automobile-accessories"}
                />
                <MultiProductCard
                  headingText={"New Arrival"}
                  keyword={"Home & Kitchen"}
                  urlText={"home-kitchen"}
                /> 
              </div>
 
              <div style={{ marginTop: "40px" }}>
                <div className="products row">
                   <FeaturedProducts
                    headingText={"FEATURED PRODUCTS"}
                    keyword={"headset,blade,fixture,pencil, car"}
                  />
                  <FeaturedProducts
                    headingText={"BEST SELLING PRODUCTS"}
                    keyword={"Mobile Accessories"}
                  />
                  <FeaturedProducts
                    headingText={"ELECTRONICS"}
                    keyword={"electronics, headset,electronics,mobile, clip"}
                  />
                  <FeaturedProducts
                    headingText={"TOP RATED PRODUCTS"}
                    keyword={"Beauty, rail,phone, car"}
                  />
                  <div
                    className="col-lg-12 order-1 order-lg-2"
                    style={{ marginTop: "40px" }}
                  >
                   {/* 
                    <h4
                      className="font-weight-semibold text-4 mb-3"
                      style={{ textAlign: "center" }}
                    >
                      NEW ARRIVALS
                    </h4>
                    <br />
                  <Products
                      parentCallback={this.callbackFunction.bind(this)}
                      price={this.state.price}
                      search={{ keyword: "*:*", width: "350px" }}
                      row={3}
                      /> */}
                  </div>
                </div>
              </div>

              {/* <ProductCrousel /> */}
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    );
  }
}
