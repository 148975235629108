import React from 'react'
import Beauty_Grooming1 from './Beauty_Grooming1'
import Beauty_Grooming2 from './Beauty_Grooming2'
import Beauty_Grooming3 from './Beauty_Grooming3'
import Beauty_Grooming4 from './Beauty_Grooming4'
export default function Beauty_Grooming() {
  return (
    <>
        <Beauty_Grooming1/>
        <Beauty_Grooming2/>
        <Beauty_Grooming3/>
        <Beauty_Grooming4/>
    </>
  )
}
