import React, { Component } from "react";
import CommonFunctions from "../global/CommonFunctions";
import { NavLink, Link } from "react-router-dom";
class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: props.search.keyword ?? "",
      category: props.search.category ?? "search",
      val:''
    };
    // console.log("this.props.cats",(this.props.cats)); 
    
  }
  componentDidMount() {
  
    // Changing the state after 2 sec
    // from the time when the component
    // is rendered
    setTimeout(() => {
      this.setState({ color: 'wheat' });
    }, 2000);
  }

  render() {
    return (
      <aside className="sidebar ">
    
        <h5 className="font-weight-semi-bold pt-3">Categories</h5>
        <div className="scroll">
        <ul className="nav nav-list flex-column ">
       

       { 
        this.props.cats ?
        Object.entries(this.props.cats).map(([cat, count], key) => {
                return CommonFunctions.getValidURL(cat) ===
                  CommonFunctions.getValidURL(this.state.category)  ? (
                  null
                ) : (
                <div>
                {
                  cat.length>0 ?
                  
                  <li className="nav-item" key={key}>
                  
                 
                  <a
                    className="nav-link"
                    href={"/" + CommonFunctions.getValidURL(cat)}
                  >
               
                    {cat}
                  </a>
                </li> : null
                }
               
                </div>
                 
                );
              })
            :<div class="loader">Loading...</div>}
        </ul>
        </div>
      </aside>
    );
  }
}

export default LeftSidebar;
