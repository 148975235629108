import React, { Component, Suspense, lazy } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import LeftSidebar from "../components/LeftSidebar";
import LeftSidebarTags from "../components/LeftSidebarTags";
import LeftSidebarProducts from "../components/LeftSidebarProducts";
import {ScrollToTop} from "../components/ScrollToTop"
import SearchFilter from "../components/SearchFilter";
import Products from "../components/Products";

export default class SearchScreen extends Component {
  constructor() {
    super();
    this.state = {
      tags: {},
      cats: {},
      price: [],
      stats: { min: 0, max: 0 },
    };
    // console.log("this.props.cats",(this.state.cats));
  }
  scrollTop()
{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}
  callbackFunction(state = {}) {
    this.setState(state);
  }
  render() {
    return (
      <div>
      <ScrollToTop/>
        <div className="body" style={{ transition: "margin 300ms ease 0s" }}>
          <Header search={{ ...this.props.match.params }} />
          <div role="main" className="main shop">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 order-2 order-lg-1">
                  <div id="left-side-scroller">
                    {/* <LeftSidebar
                      cats={this.state.cats}
                      search={{ ...this.props.match.params }}
                    />
                    <br />
                      
                    {this.state.stats.max > 0 && (
                      <button style={{marginLeft:"500px"}} onClick={this.scrollTop}> Move to top</button>
                      <SearchFilter
                        stats={this.state.stats}
                        parentCallback={this.callbackFunction.bind(this)}
                      />
                    )}
                    */}
                    {this.state.tags && Object.keys(this.state.tags).length ? (
                      <h5 className="font-weight-semi-bold ">Trending Search</h5>
                    ) : (
                      ""
                    )}
                   
                     <LeftSidebarTags id="left-side-scroller" tags={this.state.tags} />
                  {/*  <LeftSidebarProducts
                      headingText={"TOP RATED PRODUCTS"}
                      search={{ ...this.props.match.params }}
                    />
                    */}
                  </div>
                    </div>
                <div className="col-lg-9 order-1 order-lg-2">
                  <Products
                    parentCallback={this.callbackFunction.bind(this)}
                    price={this.state.price}
                    search={{ ...this.props.match.params }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <Footer />
        
      </div>
    );
  }
}
