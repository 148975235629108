import React, { Component } from 'react'
import axios from "axios";
import { NavLink } from "react-router-dom";
import cf from '../../global/CommonFunctions';

export default class Photography4 extends Component {

    constructor(props) {
       
        super(props)
        this.state = {
            ourProductCrouselList: []
            
    
    
    
        }
        this.tags = cf.getTags();
    }

    componentDidMount() {
    
        this.ourProductCrousel();
        
      
      }

      ourProductCrousel() {
        this.setState({loading: true})
        let params = {
          
            "category": "Hair Care & Styling",
            "facet": true,
            "keyword": "",
            "limit": 8,
            "pageNumber": 1,
            
            "price": [],
            "sortBy": {"price": 0},
            "source": "web",
            "type": 1
        }
          
        
        axios.post(process.env.REACT_APP_NODE_API_URL.trim() + "/shopperrb2b/solr/searchProducts", params)
            .then(res => {
                console.log(res.data.Response.data.products)
                this.setState({ ourProductCrouselList: res.data.Response.data.products })
                 
            })
      }
  render() {
    return (
      <>
        <div className='container' style={{marginTop:'50px'}}>
      {/* <h1 style={{textAlign:'center'}}>Featured Products</h1> */}
            <div className='row'>

            {
               
                          
               this.state.ourProductCrouselList?.map((item, index) => {
                  
                   return ( 
            <div className='"col-sm-6 col-lg-3'>
               
        <div className="product mb-0">
            <div className="product-thumb-info border-0 mb-3">
            <NavLink className="product-desc text-color-dark text-color-hover-primary"
                            to={'/product/' + item.handle}
                            exact
                          >
                <div className="product-thumb-info-image">
                <span
                    className=" lazy-load-image-background  lazy-load-image-loaded"
                    style={{ display: "inline-block", height: 250, width: 250 }}
                >
                    <img
                    height="250px"
                    width="250px"
                    src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""}
                    />
                </span>
                </div>
                </NavLink>
            </div>
            <div className="d-flex justify-content-between">
            <div>
                <a
                className="d-block text-uppercase  text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                href="/undefined"
                />
                <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                <NavLink
                                className="product-desc text-color-dark text-color-hover-primary"
                                to={'/product/' + item.handle}
                                exact
                              >
                                {item.title}
                              </NavLink>
                </h3>
            </div>
            <a
                href=""
                className="text-decoration-none text-color-default text-color-hover-dark text-4"
                style={{ display: "none" }}
            >
                <i className="far fa-heart" />
            </a>
            </div>
            
            <p className="price text-5 mb-3">
            <span className="sale text-color-dark font-weight-semi-bold">
            {cf.getPriceWithCurrency(new Number(item.displayPrice).toFixed(2))}
            </span>
            <span className="amount">{cf.getPriceWithCurrency(new Number(item.recommendedRetailPrice).toFixed(2))}</span>
            </p>
        </div>
       
</div>
 )
            })
             
          } 


            </div>
        </div>
      </>
    )
  }
}
