import React, { Component } from "react";
import MetaTags from "react-meta-tags";

class TermsConditions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Terms and Conditions</title>
          <meta
            id="description"
            name="mg-description"
            content="Terms and Conditions In Shopperr."
          />
          <meta
            id="keywords"
            property="mg-keyword"
            content="head,body,html,tags"
          />
        </MetaTags>
        <div className="container">
          {" "}
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="font-weight-normal text-7 mb-2"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                <strong className="font-weight-extra-bold">
                  Terms and Conditions
                </strong>
              </h2>
              <p>
                The Shopperr.in website is operated by One Stop Fashions Pvt.
                Ltd. at 436, Jawala Mill Road, Electronic City, Phase-IV ,
                Sec-18, Udyog Vihar, Gurugram, India or its affiliates. These
                Terms of Service apply to Shopperr and not to the content of
                third parties.
              </p>
              <strong>Acceptance of terms of use</strong>
              <p>
                These Terms and other applicable conditions and notices
                contained herein govern your use of the Shopperr service and
                website. Your use of a particular Shopperr website included
                within the Shopperr area may also be subject to additional terms
                outlined elsewhere on those sites. By using, visiting, or
                browsing the Shopperr website(s), you accept and agree to be
                bound by these Terms of Use. If you do not agree to these Terms
                of Use, you should not use the Shopperr service or website.
                Please note, return shipments sent to the address above will be
                refused. Please contact our customer support team for
                instructions before returning products.
              </p>
              <p>
                These Terms of Use are an ongoing contract between you and
                Shopperr and apply to your use of the Shopperr service and
                website. These Terms of Use affect your rights and you should
                read them carefully.
              </p>
              <strong>Changes to terms of use</strong>

              <p>
                Shopperr reserves the right, from time to time, with or without
                notice to you, to change these Terms of Use in our sole and
                absolute discretion. The most current version of these Terms of
                Use can be reviewed by clicking on the links that direct you to
                the Terms on the Shopperr website. The most current version of
                the Terms will supersede all previous versions. Your use of the
                Shopperr website or continued use of our service after changes
                are made means that you agree to be bound by such changes.
              </p>
              <div className="sticky-container">
                <div className="row">
                  <div className="col-lg-8">
                    <strong>Membership & privacy policy agreement</strong>

                    <p>
                      You agree to our Membership & Privacy Policy Agreement.
                      Any information submitted on the Shopperr website is
                      subject to our Membership & Privacy Policy Agreement.
                      Please review this policy to understand our practices.
                      Product Pricing
                    </p>
                    <strong>Changes to terms of use</strong>

                    <p>
                      Shopperr reserves the right, from time to time, with or
                      without notice to you, to change these Terms of Use in our
                      sole and absolute discretion. The most current version of
                      these Terms of Use can be reviewed by clicking on the
                      links that direct you to the Terms on the Shopperr
                      website. The most current version of the Terms will
                      supersede all previous versions. Your use of the Shopperr
                      website or continued use of our service after changes are
                      made means that you agree to be bound by such changes.
                    </p>
                    <p>
                      We reserve the right to adjust prices at any time. Due to
                      possible information misrepresentations, we may correct
                      typographical and printing errors related to prices at any
                      time. Members should be aware that product prices can and
                      do fluctuate as a result of a supplier updating their
                      wholesale pricing. Shopperr has no control over any
                      supplier’s pricing, shipping and handling charge policies
                      and therefore cannot guarantee any item will remain at any
                      particular price for any period of time. Membership and
                      billing.
                    </p>
                    <p>
                      You can find the specific details regarding your purchase
                      and billing with Shopperr at anytime by clicking on the
                      “Billing Details” link within the “My Account” section of
                      your Shopperr retailer account; you must be “logged in” to
                      view this section.
                    </p>
                  </div>
                </div>
              </div>
              <strong>Free Trials</strong>

              <p>
                We encourage the use of our service through free trials. View
                the specific details regarding your free trial at anytime by
                clicking on the “Billing Details” link within the “My Account”
                section of your Shopperr retailer account; you must be “logged
                in” to view this section. Free trials are only available to
                first time customers of the Shopperr service and cannot be
                combined with any other company offer. Only one free trial may
                be redeemed by a retailer or retailer’s organization and its
                agents. You must have Internet access and a valid form of
                currently-accepted payment such as a credit card, debit card, or
                alternative valid form of currently accepted payment (“Payment
                Method”) to redeem a free trial offer. Upon registering for your
                free trial, your Payment Method may be authorized up to the term
                of service for which you joined. In some instances, your
                available balance or credit limit may reflect the authorization;
                however, no charges will be made against the Payment Method
                unless you do not cancel prior to the end of your free trial
                period. The day you start your trial is considered ‘Day 1’ of
                the trial, regardless of the time of day you register. Please
                note, Shopperr uses the Mountain time zone (MT) to calculate all
                free trial periods. Once the free trial has ended, you will be
                prompted to purchase a membership subscription to continue with
                your Shopperr service. Upon registering for your membership
                subscription, your Payment Methods will be charged the amount
                described, up to the term of service for which you joined. We
                will then begin billing your Payment Method for subscription
                fees corresponding to your subscription program on a yearly or
                monthly basis plus any applicable tax. We will continue to bill
                your Payment Method on a monthly or yearly basis for your
                subscription program until you cancel. You may cancel your
                subscription at anytime through your online cancellation button.
                We offer a number of subscription programs, including special
                promotional programs with differing pricing, length of
                subscription terms, and bundles. Some of these promotions are
                offered by third parties in conjunction with the provision of
                their own products and services. We are not responsible for the
                products and services provided by such third parties. We reserve
                the right to modify, terminate or otherwise amend our offered
                subscription programs. If you are contacted by a Shopperr
                representative within your free trial and agree to a promotional
                membership upgrade, your free trial is void the moment you
                submit payment.
              </p>
              <strong>Billing</strong>

              <p>
                By using the Shopperr service, you are expressly agreeing that
                we are permitted to bill you a recurring monthly or yearly
                subscription fee, any applicable tax and any other charges you
                may incur in connection with your use of the Shopperr service.
                As used in these Terms of Use, “billing” shall indicate either a
                charge or debit, as applicable, against your Payment Method. The
                subscription fee will be billed at the beginning of your
                subscription and on each monthly or yearly renewal thereafter
                unless and until you cancel your membership. Click on the
                “Billing Details” link within the “My Account” section of your
                Shopperr retailer account to see the commencement date for your
                next renewal period. We will automatically bill or attempt to
                bill your Payment Method each month or year on the calendar day
                corresponding to the commencement of your membership. In the
                event your membership began on a day not contained in a given
                month, we will bill or attempt to bill your Payment Method on
                the last day of such month. For example, if you became a paying
                member with a monthly subscription on January 31st, your Payment
                Method would next attempt to bill on February 28th. All fees and
                charges are nonrefundable. We may change the fees and charges in
                effect, or add new fees and charges from time to time, but we
                will give you advance notice of these changes by email or within
                your account upon login. If you want to use a different Payment
                Method or if there is a change in your credit card validity or
                expiration date, you may edit your Payment Method information by
                clicking on the “My Account” button, available at the top of the
                pages of the Shopperr website. If your Payment Method reaches
                its expiration date, your continued access of the service
                constitutes your authorization for us to continue billing that
                Payment Method. If Shopperr attempts to bill your Payment Method
                and does so unsuccessfully, you grant Shopperr the right to
                re-attempt to process and bill your Payment Method for up to 180
                days as well as bill your Payment Method for any unsuccessful
                accruing billings during such time.
              </p>

              <strong>Automatic Renewals</strong>

              <p>
                Your Shopperr subscription will be automatically renewed on a
                monthly or yearly basis. For your convenience, we will bill the
                monthly or annual subscription fee, plus any applicable tax, to
                the Payment Method you provide to us during registration (or to
                a different Payment Method if you change your account
                information). Your membership will automatically renew for
                successive monthly or yearly subscriptions, without prior notice
                to you, unless and until you cancel your membership or we
                terminate it. You must cancel your membership before it renews
                each month or year in order to avoid billing of the next
                month’s/year’s subscription fees to your Payment Method. You are
                not eligible to receive refunds once your Payment Method has
                been billed.
              </p>
              <strong>Cancellation</strong>

              <p>
                You may cancel your trial/monthly/annual subscription to
                Shopperr at any time: through our online cancellation button
                through an email to Customer Support through online live chat
                (available to Shopperr Advanced, Pro, and Pro-plus retailers) WE
                DO NOT PROVIDE REFUNDS OR CREDITS FOR ANY PARTIAL-MONTH or YEAR
                SUBSCRIPTION PERIODS. We reserve the right to terminate your
                account for any or no reason.
              </p>
              <strong>Electronic communications</strong>

              <p>
                By using the Shopperr service, you consent to receiving
                electronic, phone, and mail communications from Shopperr. These
                communications will include notices about your account (e.g.,
                order updates and receiving e-mails) and information concerning
                or related to our service. These communications are part of your
                relationship with Shopperr and you are entitled to receive them
                as part of the Shopperr subscription. You agree that any notice,
                agreements, disclosure or other communications that we send to
                you electronically will satisfy any legal communication
                requirements, including that such communications be in writing.
              </p>
              <strong>Service testing</strong>

              <p>
                From time to time, we test various aspects of our service, and
                we reserve the right to include you in these tests without
                notice.
              </p>
              <strong>Use of information submitted</strong>

              <p>
                Shopperr is free to use any comments, information, ideas,
                concepts, reviews, or techniques contained in any communication
                you may send to Shopperr, including, without limitation,
                responses to questionnaires or through postings to the Shopperr
                website without further compensation, acknowledgment or payment
                to you for any purpose whatsoever including, but not limited to,
                developing, manufacturing and marketing products and creating,
                modifying or improving the Shopperr website or other websites.
                Furthermore, by posting any information on our site, submitting
                suggestions, or in responding to questionnaires, you grant us a
                nonexclusive, royalty-free license to display, use, reproduce or
                modify that information.
              </p>
              <strong>Intellectual property</strong>

              <p>
                All content included on the Shopperr website and delivered to
                subscribers as part of the service, including text, graphics,
                logos, designs, photographs, button icons, images, audio/video
                clips, digital downloads, data compilations, and software, is
                the property of Shopperr, or its suppliers and is protected by
                India and international copyright laws. The compilation of all
                content on this site is the exclusive property of Shopperr, and
                protected by India and international copyright laws. Content
                should not be reproduced or used without express written
                permission from Shopperr, or its suppliers. Shopperr reserves
                the right to terminate your membership hereunder if Shopperr, in
                its sole and absolute discretion, believes that you are in
                violation of this paragraph, such violations including the
                copying or other unauthorized use of our proprietary content.
              </p>
              <strong>Trademarks</strong>

              <p>
                Shopperr is a registered trademark of Shopperr. Shopperr.in, and
                other marks indicated on our site are trademarks of Shopperr.
                All Shopperr graphics, logos, page headers, button icons,
                scripts and service names are trademarks or trade dress of
                Shopperr. Shopperr’s trademarks and trade dress may not be used
                in connection with any product or service that is not
                Shopperr’s, in any manner that is likely to cause confusion
                among customers, in any manner that dilutes Shopperr’s rights,
                or in any manner that disparages or discredits Shopperr. All
                other trademarks not owned by Shopperr that appear on the
                Shopperr website are the property of their respective owners,
                who may or may not be affiliated with, connected to, or
                sponsored by Shopperr. Any images of persons or personalities
                contained on the Shopperr website shall not be an indication of
                endorsement of any particular product or our service unless
                otherwise specifically indicated.
              </p>
              <strong>Account access & identity protection</strong>

              <p>
                In order to provide you with ease of access to your account,
                Shopperr will place a cookie (a small text file) on any computer
                from which you access the Shopperr website. When you revisit the
                Shopperr website, this cookie will enable us to recognize you as
                a previous user or as the account holder and provide you with
                direct access to your account without requiring you to retype
                any password or other user identification. You are responsible
                for updating and maintaining the truth and accuracy of the
                information you provide to us relating to your account.
              </p>
              <p>
                You are also responsible for maintaining the confidentiality of
                your account and password and for restricting access to your
                computer. You may not share your account with other people by
                providing them your password. However, if you decide to share
                your account with other people, you take full responsibility for
                their actions and liabilities. Users of public or shared
                computers should log out at the completion of each visit to the
                Shopperr website.
              </p>
              <p>
                If you find that you’re a victim of identity theft and it
                involves a Shopperr account, you should notify customer service.
                Then, you should report this instance to all your credit card
                issuers, as well as your local law enforcement agency. Shopperr
                reserves the right to place any account on hold anytime with or
                without notification to the subscriber in order to protect
                itself and its partners from what it believes to be fraudulent
                activity. Shopperr is not obligated to credit or discount a
                membership for holds placed on the account by either a
                representative of Shopperr or by the automated processes of
                Shopperr. Payment obligations.
              </p>
              <p>
                The Member understands that a valid form of payment must
                accompany all orders and membership fees.
              </p>
              <strong>Warranty</strong>

              <p>
                All warranties for products purchased through Shopperr are the
                responsibility of the respective manufacturers and their agents.
                All issues, claims and questions concerning product warranties
                should be submitted directly to the manufacturer or supplier. In
                the event that the product is a health related device or
                nutritional supplement, all information contained on the
                website, including information relating to medical and health
                conditions, products and treatments, is for informational
                purposes only. The information on the Shopperr website, or
                information provided by the manufacturers of the products, or
                information on product packaging or labels is not meant to be a
                substitute for the advice of a physician or other medical
                professional and should not be used for prescribing a medication
                or diagnosing a health problem. Statements regarding dietary
                supplements have not been evaluated by the Food and Drug
                Administration and are not intended to diagnose, treat, cure, or
                prevent any disease.
              </p>
              <strong>Copyrights</strong>

              <p>
                Shopperr and its supplier and distribution partners reserve
                ownership and copyright to all materials published by Shopperr.
                Reproductions and alterations of individual product images and
                descriptions may be used for marketing & reselling purposes. You
                must receive written consent from Shopperr for any other use or
                any modification of product images and other copyrighted
                materials. Shopperr allows its members to resell the products in
                our catalog.
              </p>
              <strong>Products</strong>

              <p>
                Shopperr diligently strives to provide the most accurate
                information as possible. However, as all specifications and
                descriptions are provided by the supplier of the product,
                Shopperr makes no warranty expressed or implied with respect to
                accuracy of the information, including price, product
                descriptions or product specifications. Both the product and
                manufacturer names are used only for the purpose of
                identification. Members are responsible for making sure the
                product information is correct with the product’s respective
                Manufacturers prior to selling any products from this site.
              </p>
              <strong>Data Export</strong>

              <p>
                Shopperr diligently strives to provide the most accurate
                information as possible. However, as all specifications and
                descriptions are provided by the supplier of the product,
                Shopperr makes no warranty expressed or implied with respect to
                accuracy of the information, including price, product
                descriptions or product specifications. In addition, due to
                size, volume of requests and resource constraints; Data Exports,
                at times, may be delayed or contain data older then what is
                displayed on the website.
              </p>
              <strong>Reselling</strong>

              <p>
                Manufacturer may require strict adherence to how their
                intellectual property is advertised. As such, some products may
                only be advertised at a Minimum Advertised Price (referred to
                herein as MAP). For all products under a MAP agreement, we will
                provide two prices: the wholesale cost and MAP. As a reseller,
                you agree to never advertise any products protected by a MAP
                agreement to end consumers for less than the MAP price. Products
                with a MAP will be clearly marked with an asterisk. Under no
                circumstances shall Shopperr or any other party involved in
                creating, distributing, or supplying products for Shopperr be
                liable for any direct, indirect, incidental, special, or
                consequential damages that result from, products purchased from
                Shopperr. It is the reseller’s responsibility to verify with the
                manufacturer, any product information, images and descriptions
                prior to marketing any and all products. Product images are
                subject to change at anytime and are not to be construed as
                exact representations of any products ordered since the
                manufacturers can change their products at any time without any
                notice.
              </p>
              <p>
                Members are not allowed to repackage, reproduce, or otherwise
                resell Shopperr memberships, technology, and/or data without
                express written consent from Shopperr. Also, bulk downloading or
                “screenscraping” of data from the Shopperr website or subsidiary
                websites is prohibited without prior express written permission.
                Violation will result in the account being canceled and
                depending on the severity of the violation, we reserve the right
                to press criminal charges and/or seek civil compensation for
                damages, expenses, and revenues lost.
              </p>
              <strong>Contacting Shopperr Suppliers</strong>

              <p>
                Shopperr provides access to information about suppliers
                including but not limited to, trade name, brand(s), and product
                specifications, pricing and availability. Shopperr’s agreement
                with the supplier may restrict Shopperr members from contacting
                the supplier directly. At Shopperr’s sole discretion, your
                account may be canceled, without refund, if the member attempts
                to contact and establish a direct relationship with Shopperr’s
                suppliers. Shopperr will make every attempt, within reason, to
                accommodate a member request for additional products, pricing,
                or services from a supplier on behalf of the member. Shipping
                and handling.
              </p>
              <p>
                Shopperr and its distributors/suppliers reserve the right to
                restrict shipments to within India. All orders must be placed
                with a residential ship-to address. Orders with P.O. Box, APO,
                or FPO shipping addresses will not be processed. Any orders with
                shipping addresses outside India will not be processed.
                Individual suppliers may have additional shipping restrictions.
                However, in no way does the absence of a specific shipping
                policy by a supplier imply that the supplier offers any of the
                above mentioned services. Shipping and handling charges.
              </p>
              <p>
                You are responsible to pay the shipping and handling charges for
                all products purchased from Shopperr. You are responsible for
                these charges for shipments that are refused or returned for any
                non-supplier caused. We are not responsible for incidental or
                consequential damages or losses to defective products, errors in
                shipping your order, or other errors. The limit of our liability
                is the replacement cost of any item you purchase from us. ANY
                ODD SIZED OR EXCESSIVE WEIGHTED ITEMS MAY REQUIRE A CUSTOM
                SHIPPING COST CALCULATION FROM Shopperr. ON THESE OCCASIONS, THE
                ORDERS WILL NOT SHIP UNTIL YOU HAVE APPROVED THE CUSTOM SHIPPING
                COST. Depending on information provided by the supplier,
                Shopperr may publish the shipping weights of items. The
                publication, or absence of, shipping weights cannot be used to
                determine ship cost and/or your financial obligation. The ‘Ship
                Cost’ provided on every product page and in the shopping cart
                should solely be used for determining the ship cost. Order
                placement.
              </p>
              <p>
                Shopperr is not responsible for loss, or damages as a result of
                misplaced, mis-ordered or other typographical errors that occur
                during order placement. The members has sole responsibility for
                verifying the accuracy of the order, including actual item,
                pricing, shipping, and/or handling, prior to order submission.
                Order placement means (single, batch or API) does not change
                member responsibility and subsequent financial responsibility.
                Order changes.
              </p>
              <p>
                Generally, once an order is placed, it cannot be changed in any
                way. However, under certain circumstances, orders can be
                canceled and/or modified. If the Member contacts Shopperr’s
                customer service department using the online form as soon as
                possible with a change request, it may be occasionally possible
                to amend the order. Shopperr is not responsible for any loss, or
                damages that are a result of a change request occurring after
                the order has been placed. Order returns.
              </p>
              <p>
                Each one of Shopperr’s suppliers has a different return policy.
                Members are responsible for becoming familiar with and abiding
                by the policies and procedures of each supplier for returning
                products ordered. Failure to do so may result in a rejected
                return. Individual supplier policies can be viewed by clicking
                the individual “Supplier Info” links located on the “Products”
                tab of the Shopperr site. Fraud Protection.
              </p>
              <p>
                As a client of Shopperr, you understand that you are an
                absolutely independent entity from Shopperr. You are responsible
                to pay taxes to state, federal or local authorities in
                accordance with any and all applicable laws. This tax
                responsibility includes collecting and remitting all appropriate
                sales taxes to state and local authorities. Relationship between
                Shopperr and the reseller (you).
              </p>
              <p>
                Resellers are independent contractors of Shopperr and are not
                considered employees of Shopperr. Resellers are prohibited from
                making any unauthorized claims by the manufacturer, or claims
                that are illegal in any state. Resellers do not have the right
                to bind Shopperr in any contract related to the business of the
                reseller. Reseller affirms that reseller is of legal adult age
                in the state in which reseller resides.
              </p>
              <p>
                The visitor/member, by completing the signup process, hereby
                represents that they are of the legal age of the state in which
                they reside.
              </p>
              <strong>Products sales & venue disclaimer</strong>

              <p>
                Although all of the suppliers Shopperr works with are authorized
                distributors of the products they sell, and Shopperr is
                authorized to act as a venue to supply said products, this does
                not indicate that all items are suitable for resale under any
                particular circumstance or venue. Shopperr acts only as a venue
                to provide access to wholesale and bulk products.
              </p>
              <p>
                It is the member’s responsibility to ascertain any particular
                product’s suitability for sale in any given venue that the
                customer chooses to sell through, whether this is through a
                “brick & mortar” store, an online store, online auctions, or any
                other venue. Members are responsible to discover and comply with
                any specific manufacturer policies or MAP (minimum advertised
                price) restrictions.
              </p>
              <p>
                It is also the member’s responsibility as a businessperson to
                ascertain the legal implications of selling items that may be
                prohibited, by law or otherwise, in certain regions or venues.
              </p>
              <p>
                For anyone who plans to sell items through an online marketplace
                (or auction) site, it is recommended that you review the terms
                and conditions of the online marketplace, as well as check to be
                sure any product you hope to sell is not prohibited by the
                online marketplace.
              </p>
              <p>
                Please note, some items which are prohibited are not necessarily
                against the law to sell, but are simply items that a marketplace
                has decided to prohibit based on their own decision.
              </p>
              <p>
                Shopperr has no control over what is and isn’t legal in any
                online marketplace, venue or region and is not responsible for
                the sale of any product through our services. Legal recourse.
              </p>
              <p>
                You and Shopperr agree that the laws of the State of Utah and
                Utah County shall have exclusive jurisdiction over any dispute
                or claims, policies, and procedures between you and Shopperr
                relating in any way to the Shopperr service or website or these
                Terms. You and Shopperr expressly and irrevocably consent to
                personal jurisdiction and venue in these courts. The
                non-prevailing party shall pay all attorney’s fees and court
                costs. Limitations on use.
              </p>
              <p>
                You must be 18 years of age or older to subscribe to the
                Shopperr service. While individuals under the age of 18 may
                utilize the service, they may do so only with the involvement of
                a parent or legal guardian. Unless otherwise specified we grant
                you a limited license to access the Shopperr website. You may
                not download (other than through page caching necessary for
                personal use), modify, copy, distribute, transmit, display,
                perform, reproduce, duplicate, publish, license, create
                derivative works from, or offer for sale any information
                contained on, or obtained from, the Shopperr website without our
                express written consent. Any unauthorized use of the Shopperr
                website or its contents will terminate the limited license
                granted by us.
              </p>
              <strong>Confidentiality</strong>

              <p>
                Reseller understands that all information provided by Shopperr
                is confidential and proprietary to Shopperr and must be used by
                reseller according to the terms of this agreement. Reseller
                agrees to not provide any confidential or proprietary
                information received from Shopperr to any business entity that
                may compete with Shopperr. Any violation of confidentiality is
                grounds for immediate termination. Amendments.
              </p>
              <p>
                Shopperr reserves the right to make amendments to this agreement
                at any time. Amendments may be the result of changes in
                economic, legal, or other factors that affect the business of
                Shopperr. Data export.
              </p>
              <p>
                Due to the nature of the data export no refunds will be granted
                for this service.
              </p>
              <strong>Payment</strong>

              <p>
                It is the Member’s responsibility to obtain payment or assurance
                of payment from their individual customers before ordering with
                Shopperr. Shopperr strongly recommends obtaining proper address
                verification systems (AVS) for credit card purchases and making
                sure all physical payments are deposited and cleared before
                placing the order with Shopperr. Orders placed with Shopperr are
                very difficult to cancel, and are subject to any restocking
                fees. All payment shall be made via credit/debit card payment,
                Verified PayPal payment, Shopperr PrePay, or wire transfer for
                product orders. Shopperr reserves the right to restrict payment
                to wire transfer for orders over $2,000 (two-thousand dollars)
                or any other order deemed to have high fraud risk. All
                membership payment shall be made via major credit/debit cards,
                Shopperr PrePay, or Verified PayPal payment. Shopperr will
                accept no other payment form than those here listed.
              </p>
              <strong>Severability</strong>

              <p>
                If one or more of the provisions of the policies and procedures
                in this user agreement are deemed void by law in a court of
                competent jurisdiction, the balance of the policies and
                procedures’ remaining provisions, or any provision in the
                Shopperr manual, shall continue in full force and effect.
              </p>
              <strong>Website information</strong>

              <p>
                While www.shopperr.in (hereafter the “Website”) and all of its
                dependent pages contain far too much information to be quoted in
                their entirety in these Terms and Conditions, the Member is
                responsible for reading and understanding all information
                contained on the Website. Shopperr is not responsible for any
                inconvenience, loss of profit, or other complication that the
                Member, his or her business, or his or her customers may
                experience as a result of the Member’s failure to read and
                understand all information on the Website. Furthermore, it is
                the responsibility of the Member to contact and question
                Shopperr’s customer service representatives regarding any
                portion of the Website that they have read but do not
                understand. The Member, for example, is responsible for all
                information contained in the following pages of the website as
                well as all information that is accessible by following links
                found on the following pages:
              </p>
              <p>
                In addition to all information contained on the Website, the
                Member is accountable for any and all new information added to
                the Website at any time in the future. Such information is
                often, but not always, referenced in the Press Room.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
