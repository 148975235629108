import React from 'react'
import Home_Kitchen1 from './Home_Kitchen1'
import Home_Kitchen2 from './Home_Kitchen2'
import Home_Kitchen3 from './Home_Kitchen3'
import Home_Kitchen4 from './Home_Kitchen4'
export default function Home_Kitchen() {
  return (
    <>
        <Home_Kitchen1/>
        <Home_Kitchen2/>
        <Home_Kitchen3/>
        <Home_Kitchen4/>
    </>
  )
}
