import React, { Component } from "react";
import axios from "axios";

class ProductCrousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsCrouselList: [],
    };
  }
  componentDidMount() {
    this.productCrouselHomeWidget();
  }

  productCrouselHomeWidget() {
    let params = {
      keyword: this.state.keyword,
      type: 1,
      limit: 3,
      pageNumber: 1,
      source: "web",
    };

    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/shopperrb2b/solr/toprated",
        params
      )
      .then((res) => {
        this.setState({ productsCrouselList: res.data.Response.data.products });
      });
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <h4>Default</h4>
          <div
            className="owl-carousel owl-theme stage-margin owl-loaded owl-drag owl-carousel-init"
            data-plugin-options="{'items': 5, 'margin': 10, 'loop': false, 'nav': true, 'dots': false, 'stagePadding': 40}"
            style={{ height: "auto" }}
          >
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-697px, 0px, 0px)",
                  transition: "all 0.25s ease 0s",
                  width: 1824,
                  paddingLeft: 40,
                  paddingRight: 40,
                }}
              >
                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-5.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>

                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>

                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-6.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-7.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-7.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-7.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "164.333px", marginRight: 10 }}
                >
                  <div>
                    <img
                      alt
                      className="img-fluid rounded"
                      src="img/projects/project-7.jpg"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a
                        href="#"
                        className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                      >
                        sports
                      </a>
                      <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <a
                          href="shop-product-sidebar-right.html"
                          className="text-color-dark text-color-hover-primary"
                        >
                          Workout
                        </a>
                      </h3>
                    </div>
                    <a
                      href="#"
                      className="text-decoration-none text-color-default text-color-hover-dark text-4"
                    >
                      <i className="far fa-heart" />
                    </a>
                  </div>
                  <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                      $40,00
                    </span>
                    <span className="amount">$30,00</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="owl-nav">
              <button type="button" role="presentation" className="owl-prev" />
              <button
                type="button"
                role="presentation"
                className="owl-next disabled"
              />
            </div>
            <div className="owl-dots disabled" />
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCrousel;
