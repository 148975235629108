import React, { Component } from "react";
import User from "../../global/User";
import axios from "axios";
import cf from "../../global/CommonFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      cartDetail: null,
    };
    this.messegeFlash = 0;
  }
  componentDidMount() {
    this.orderDetails();
  }
  orderDetails(params = { couponCode: "" }) {
    let user = User.getUser();
    if (!user) window.location.href = "/";
    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/sscapi/web/checkout",
        {
          key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
          token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
          merchantId: user.id,
          source: "web",
          isconfirm: 0,
          discountCode: params.couponCode,
        }
      )
      .then((res) => {
        if (res.data.Response.status !== 200) {
          return toast.error(res.data.Response.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.setState({ cartDetail: res.data.Response.data });
        if (res.data.Response.data.discountCodes.length && this.messegeFlash) {
          this.messegeFlash = 1;
          toast.success("Your coupon code has been applied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log("Error in Single Product APi", err);
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.couponCode) {
      this.setState({ couponCode: nextProps.couponCode });
      this.orderDetails({ couponCode: nextProps.couponCode });
    }
  }
  render() {
    return (
      <div
        className="card border-width-3 border-radius-0 border-color-hover-dark"
        data-plugin-sticky
        data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}"
        style={{ width: 445 }}
      >
        {this.state.cartDetail && (
          <div className="card-body">
            <h4 className="font-weight-bold text-uppercase text-4 mb-3">
              Your Order
            </h4>
            <table className="shop_table cart-totals mb-3">
              <tbody>
                <tr>
                  <td colSpan={2} className="border-top-0">
                    <strong className="text-color-dark">Product</strong>
                  </td>
                </tr>
                {this.state.cartDetail.products.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <strong className="d-block text-color-dark line-height-1 font-weight-semibold">
                          {el.name.substr(0, 15) + "..."}
                          <span className="product-qty">
                            x{el.cartQuantity}
                          </span>
                        </strong>
                        {/* <span className="text-1">COLOR BLACK</span> */}
                      </td>
                      <td className="text-right align-top">
                        <span className="amount font-weight-medium text-color-grey">
                          {cf.getPriceWithCurrency(el.price)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
                <tr className="cart-subtotal">
                  <td className="border-top-0">
                    <strong className="text-color-dark">Subtotal</strong>
                  </td>
                  <td className="border-top-0 text-right">
                    <strong>
                      <span className="amount font-weight-medium">
                        {cf.getPriceWithCurrency(
                          this.state.cartDetail.customer.total_price_of_products
                        )}
                      </span>
                    </strong>
                  </td>
                </tr>
                <tr className="shipping">
                  <td colSpan={2}>
                    <strong className="d-block text-color-dark mb-2">
                      Shipping
                    </strong>
                    <div className="d-flex flex-column">
                      <label
                        className="d-flex align-items-center text-color-grey mb-0"
                        htmlFor="shipping_method1"
                      >
                        <strong className="d-block text-color-dark mb-2">
                          GST:-{" "}
                        </strong>
                        <strong>
                          <span className="amount font-weight-medium">
                            {cf.getPriceWithCurrency(
                              this.state.cartDetail.customer.total_gst
                            )}
                          </span>
                        </strong>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr className="total">
                  <td>
                    <strong className="text-color-dark text-3-4">Total</strong>
                  </td>
                  <td className="text-right">
                    <strong className="text-color-dark">
                      <span className="amount text-color-dark text-5">
                        {cf.getPriceWithCurrency(
                          this.state.cartDetail.customer.price
                        )}
                      </span>
                    </strong>
                  </td>
                </tr>
                {/* <tr className="payment-methods">
                            <td colSpan={2}>
                                <strong className="d-block text-color-dark mb-2">
                                    Payment Methods
                                </strong>
                                <div className="d-flex flex-column">
                                    <label
                                        className="d-flex align-items-center text-color-grey mb-0"
                                        htmlFor="payment_method1"
                                    >
                                        <input
                                            id="payment_method1"
                                            type="radio"
                                            className="mr-2"
                                            name="payment_method"
                                            defaultValue="cash-on-delivery"
                                            defaultChecked
                                        />
                                        Cash On Delivery
                                    </label>
                                    <label
                                        className="d-flex align-items-center text-color-grey mb-0"
                                        htmlFor="payment_method2"
                                    >
                                        <input
                                            id="payment_method2"
                                            type="radio"
                                            className="mr-2"
                                            name="payment_method"
                                            defaultValue="paypal"
                                        />
                                        PayPal
                                    </label>
                                </div>
                            </td>
                        </tr> */}
                <tr>
                  <td colSpan={2}>
                    Your personal data will be used to process your order,
                    support your experience throughout this website, and for
                    other purposes described in our privacy policy.
                  </td>
                </tr>
              </tbody>
            </table>
            {parseInt(this.state.cartDetail.customer.price) > 0 && (
              <button
                type="submit"
                className="btn btn-dark btn-modern btn-block text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
              >
                Place Order <i className="fas fa-arrow-right ml-2" />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}
