import React, { Component } from "react"
import Header from '../Header.js';
import Footer from '../Footer.js';
import Mobile_Computer from './Mobile_Computer'
import Beauty_Grooming from './Beauty_Grooming'
import Home_Kitchen from './Home_Kitchen'
import Jewellery from './Jewellery'
import Automobile_Accessories from './Automobile_Accessories'
import Gifts from './Gifts'
import Footwear from './Footwear'
import Home_Improvement from './Home_Improvement'
import Photography from './Photography'
import Clothing_Accessories from './Clothing_Accessories'
import Sports_Fitness from './Sports_Fitness'
// import Sports_Fitness from './Sports_Fitness'
class Categories extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (

            <div>
                <div className="body">
                    <Header />
                    {(() => {



                        switch (this.props.match.params.categoriesname) {

                            case 'mobile-computers':

                                return (

                                    <Mobile_Computer/>

                                )

                            case 'beauty-grooming':

                                return (
    
                                    <Beauty_Grooming />
    
                                )  
                                    
                                    
                            case 'home-kitchen':

                                return (

                                    <Home_Kitchen/>

                                )

                                case 'jewellery':

                                    return (
    
                                        <Jewellery />
    
                                    )
    
                            case 'home-improvement':

                                return (

                                    <Home_Improvement />
                                )

                            case 'clothing-accessories':

                                return (

                                    <Clothing_Accessories />


                                )

                            case 'footwear':

                                return (

                                    <Footwear />

                                )

                            case 'sports-fitness':

                                return (

                                    <Sports_Fitness />

                                )

                            case 'sports-fitness':

                                return (

                                    <Beauty_Grooming />
                                )



                            default:

                                return (

                                    <div></div>

                                )

                        }
                    })()}
                    <Footer />
                </div>

            </div>

        )
    }
}


export default Categories;