import React, { Component } from "react";
import cf from "../../global/CommonFunctions";
import { NavLink } from "react-router-dom";

export default class CartTotal extends Component {
  constructor(props) {
    super(props);
    const { cartDetail } = this.props.cartDetail;
    this.state = {
      cartDetail: cartDetail || {
        netAmount: 0,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ cartDetail: nextProps.cartDetail });
  }
  render() {
    return (
      <div className="col-lg-4 position-relative">
        <div className="pin-wrapper" style={{ height: 462 }}>
          <div
            className="card border-width-3 border-radius-0 border-color-hover-dark"
            data-plugin-sticky
            data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}"
            style={{ width: 356 }}
          >
            <div className="card-body">
              <h4 className="font-weight-bold text-uppercase text-4 mb-3">
                Cart Totals
              </h4>
              <table className="shop_table cart-totals mb-4">
                <tbody>
                  <tr className="cart-subtotal">
                    <td className="border-top-0">
                      <strong className="text-color-dark">Subtotal</strong>
                    </td>
                    <td className="border-top-0 text-end text-right">
                      <strong>
                        <span className="amount font-weight-medium">
                          {cf.getPriceWithCurrency(
                            this.state.cartDetail.netAmount
                          )}
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr className="shipping">
                    <td colSpan={2}>
                      <strong className="d-block text-color-dark mb-2">
                        Tax
                      </strong>
                      <div className="d-flex flex-column">
                        <label
                          className="d-flex align-items-center text-color-grey mb-0 justify-content-between"
                          htmlFor="shipping_method1"
                        >
                          <strong className="d-block text-color-dark">
                            GST:-{" "}
                          </strong>
                          <strong className="amount font-weight-medium">
                            {cf.getPriceWithCurrency(this.state.cartDetail.GST)}
                          </strong>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="total">
                    <td>
                      <strong className="text-color-dark text-3-5">
                        Total
                      </strong>
                    </td>
                    <td className="text-end text-right">
                      <strong className="text-color-dark">
                        <span className="amount text-color-dark text-5">
                          {cf.getPriceWithCurrency(
                            this.state.cartDetail.grossAmount
                          )}
                        </span>
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>

              {parseInt(this.state.cartDetail.grossAmount) > 0 && (
                <NavLink
                  className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
                  to={"/checkout"}
                  exact
                >
                  Proceed to Checkout <i className="fas fa-arrow-right ms-2" />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
