import React, { Component } from "react";

import CartBreadcrumb from "./Cart/CartBreadcrumb.js";
import CheckoutLogin from "./Checkout/CheckoutLogin.js";
import CheckoutCoupon from "./Checkout/CheckoutCoupon.js";
import CheckoutDetails from "./Checkout/CheckoutDetails.js";

export default class Checkouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: null,
    };
    this.applycouponCode = this.applycouponCode.bind(this);
  }
  applycouponCode(state) {
    this.setState(state);
  }
  componentDidMount() {
    let el = document.getElementsByClassName("header-body")[0];
    if (el) {
      el.style.top = "0px";
    }
  }

  render() {
    return (
      <div role="main" className="main shop pb-4">
        <div className="container">
          <CartBreadcrumb />
          <CheckoutLogin />
          <CheckoutCoupon applycouponCode={this.applycouponCode} />
          <CheckoutDetails couponCode={this.state.couponCode} />
        </div>
      </div>
    );
  }
}
