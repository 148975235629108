import React from 'react'
import Footwear1 from './Footwear1'
import Footwear2 from './Footwear2'
import Footwear3 from './Footwear3'
import Footwear4 from './Footwear4'
export default function Footwear() {
  return (
    <>
        <Footwear1/>
        <Footwear2/>
        <Footwear3/>
        <Footwear4/>
    </>
  )
}
