import React, { Component } from "react";
import "../../css/Theme.css";
import { NavLink } from "react-router-dom";
import Pdfs from "../../img/ShopperrFaqs.pdf";
import User from "../../global/User";
import Login from "./Login";
import HeaderCart from "./HeaderCart";
class HeaderNavigation extends Component {
  handleClick = (e) => {
    e.preventDefault();
    User.logoutUser();
    window.location.reload();
  };
  componentWillMount() {
    let el = document.getElementsByClassName("header-body")[0];
    if (el) {
      el.style.top = "0px";
    }
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="header-top header-top-small-minheight header-top-simple-border-bottom">
        <div className="container">
          <div className="header-row justify-content-between">
            <div className="header-column col-auto px-0">
              <div className="header-row">
                <p className="font-weight-semibold text-1 mb-0 d-none d-sm-block d-md-none">
                  FREE SHIPPING ORDERS INR99+
                </p>
                {/* <p className="font-weight-semibold text-1 mb-0 d-none d-md-block">FREE RETURNS, STANDARD SHIPPING ORDERS INR99+</p> */}
              </div>
            </div>
            <div className="header-column justify-content-end col-auto px-0">
              <div className="header-row">
                <nav className="header-nav-top">
                  <ul className="nav nav-pills font-weight-semibold text-2">
                    <li className="nav-item dropdown nav-item-left-border d-lg-none">
                      <a
                        className="nav-link text-color-default text-color-hover-primary"
                        href="#"
                        role="button"
                        id="dropdownMobileMore"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                        <i className="fas fa-angle-down"></i>
                      </a>
                      <div className="dropdown-menu">
                        <NavLink
                          className="dropdown-item"
                          to={"/pages/about-us"}
                          exact
                        >
                          About
                        </NavLink>
                        {/* <a className="dropdown-item" href="#">Our Stores</a>
                                                <a className="dropdown-item" href="#">Blog</a> */}
                        <NavLink
                          className="dropdown-item"
                          to={"/pages/contact-us"}
                          exact
                        >
                          Contact
                        </NavLink>
                        <a
                          href={Pdfs}
                          target="_blank"
                          className="dropdown-item"
                        >
                          Help &amp; FAQs
                        </a>
                        <a className="dropdown-item" href="#">
                          Track Order
                        </a>
                      </div>
                    </li>
                    <li className="nav-item d-none d-lg-inline-block">
                      <NavLink
                        className="text-decoration-none"
                        to={"/pages/about-us"}
                        exact
                      >
                        About
                      </NavLink>
                    </li>
                    {/* <li className="nav-item d-none d-lg-inline-block">
                                            <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Our Stores</a>
                                        </li> */}
                    {/* <li className="nav-item d-none d-lg-inline-block">
                                            <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Blog</a>
                                        </li> */}
                    <li className="nav-item d-none d-lg-inline-block">
                      <NavLink
                        className="text-decoration-none"
                        to={"/pages/contact-us"}
                        exact
                      >
                        Contact
                      </NavLink>
                    </li>
                    <li className="nav-item d-none d-xl-inline-block">
                      <a
                        href="https://shopperr.zendesk.com/hc/en-us"
                        target="_blank"
                        className="text-decoration-none"
                      >
                        Help &amp; FAQs
                      </a>
                    </li>
                    {/* <li className="nav-item d-none d-xl-inline-block">
                      <NavLink
                        className="text-decoration-none"
                        to={"/pages/track-order"}
                        exact
                      >
                        Track Order
                      </NavLink>
                    </li> */}
                    <li className="nav-item d-none d-xl-inline-block">
                      {!User.getUser() ? (
                        <Login />
                      ) : (
                        <div className="userLogin">
                          <a
                            className="text-decoration-none account"
                            href={`${process.env.REACT_APP_NODE_API_URL}/merchant/dashboard`}
                            target="_blank"
                          >
                            Manage Account
                          </a>
                          <a
                            href=""
                            className="logout"
                            onClick={(e) => {
                              this.handleClick(e);
                            }}
                          >
                            Log Out
                          </a>
                        </div>
                      )}
                    </li>
                    {User.getUser() && (
                      <li className="nav-item d-none d-xl-inline-block">
                        <HeaderCart />
                      </li>
                    )}
                    {/* <li className="nav-item dropdown nav-item-left-border">
                                            <a className="nav-link text-color-default text-color-hover-primary" href="#" role="button" id="dropdownCurrency" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                INR
                                                <i className="fas fa-angle-down"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-arrow-centered min-width-0" aria-labelledby="dropdownCurrency">
                                                <a className="dropdown-item" href="#">EUR</a>
                                                <a className="dropdown-item" href="#">USD</a>
                                            </div>
                                        </li> */}
                    {/* <li className="nav-item dropdown nav-item-right-border">
                                            <a className="nav-link text-color-default text-color-hover-primary" href="#" role="button" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                ENG
                                                <i className="fas fa-angle-down"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-arrow-centered min-width-0" aria-labelledby="dropdownLanguage">
                                                <a className="dropdown-item" href="#">ESP</a>
                                                <a className="dropdown-item" href="#">FRA</a>
                                                <a className="dropdown-item" href="#">ENG</a>
                                            </div>
                                        </li> */}
                  </ul>

                  <ul className="header-social-icons social-icons social-icons-clean social-icons-icon-gray">
                    <li className="social-icons-facebook">
                      <a
                        href="http://www.facebook.com/"
                        target="_blank"
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="social-icons-twitter">
                      <a
                        href="http://www.twitter.com/"
                        target="_blank"
                        title="Twitter"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="social-icons-linkedin">
                      <a
                        href="http://www.linkedin.com/"
                        target="_blank"
                        title="Linkedin"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderNavigation;
