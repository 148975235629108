import React, { Component } from "react";
import MetaTags from "react-meta-tags";

class ReturnPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Return, Refund & Cancellation Policy</title>
          <meta
            id="description"
            name="mg-description"
            content="Return, Refund & Cancellation Policy In Shopperr."
          />
          <meta
            id="keywords"
            property="mg-keyword"
            content="head,body,html,tags"
          />
        </MetaTags>
        <div className="container">
          {" "}
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="font-weight-normal text-7 mb-2"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                <strong className="font-weight-extra-bold">
                  Return, Refund & Cancellation Policy
                </strong>
              </h2>
              <strong>Return/Refund Payment</strong>
              <strong>Possibility 1, Item Missing delivery: -</strong>
              <p>
                Out of 10 items 6 items got delivered. Therefore, you will get
                eligible to be paid back for 4 items within 5-7 working days.
              </p>

              <strong>Possibility 2, Replacement: -</strong>

              <p>We don’t have offer the replacement.</p>
              <strong>Possibility 3, RTO: -</strong>

              <p>
                Courier Returns occur when the order is undelivered and marked
                as ‘Returning to Origin’ (RTO). Please find below the potential
                reasons for the order being marked as RTO.
              </p>
              <strong>Reasons for undelivered shipment</strong>

              <p>1. Customer not contactable</p>
              <p>2. Incomplete address</p>
              <p>3. Customer refused delivery</p>
              <p>4. Door/premises/office closed</p>
              <p>
                In all of the above mentioned cases we will Partial refund 80%
                of the item price and retain 20% as logistic & packing fee.
              </p>
              <div className="sticky-container">
                <div className="row">
                  <div className="col-lg-8">
                    <strong>Return Conditions:</strong>

                    <p>1. All the products sold by us are in new condition.</p>
                    <p>
                      2. You are required to notify us upto 15 working days
                      post-delivery if in unlikely situation that the product
                      arrives damaged of faulty. With the detailed description,
                      you are requested to provide images or a short video clip
                      (depending on the nature of issue) focusing of the
                      concerned area in order to process the claim further. Once
                      the concern is assessed and approved by the customer
                      service department, we shall contact you for further
                      process.
                    </p>
                    <ul style={{ color: "#252129" }}>
                      <li>
                        {" "}
                        Refund would generally take 5-7 working days to process,
                        we shall notify you once refund has been processed.
                        However, please allow 5 additional working days for the
                        financial department to process the transaction.
                      </li>
                      <li>
                        {" "}
                        In case of product being misused, mishandled, mistakenly
                        installed or tampered there would be no replacement or
                        refund.
                      </li>
                      <li>
                        We process refund by crediting to your Shopperr
                        E-Wallet.
                      </li>
                    </ul>
                    <strong>NOTE:</strong>
                    <ul style={{ color: "#252129" }}>
                      <li>
                        Delivery time only starts after buyer’s payments have
                        been cleared and the item has been dispatched from our
                        Indian warehouse (usually 5-7 working days after the
                        product reaches Indian Warehouse).
                      </li>
                      <li>
                        We are unable to guarantee a delivery on any specific
                        dates or times as this is not something our delivery
                        partners would be able to guarantee either.
                      </li>
                      <li>
                        We are currently serving PAN India with more than 22000
                        Pin codes. Any pin code which fall under unserviceable
                        category (Remote or Rural Locations) will lead to extra
                        delivery time.
                      </li>
                      <li>
                        Situation were courier partners are not providing
                        services due to circumstances beyond control eg:
                        Lockdown, Curfew, riots, server failure, events by
                        nature (earthquake, Tsunami, volcano-no eruption, etc.),
                        government policies etc. We will take extra delivery
                        time in such cases.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <strong>Cancellation Policy</strong>
              <ul style={{ color: "#252129" }}>
                <li>
                  We process orders very quickly and it may not be possible to
                  intercept an order for cancellation. So once you will make the
                  payment of the order, we will deny the cancellation and
                  proceed with shipping and no refund.
                </li>
              </ul>
              <strong>Cancel Membership</strong>

              <ul style={{ color: "#252129" }}>
                <li>
                  You may downgrade to the free “Starter Plan” at any time and
                  we will not charge you subsequently. We do not provide partial
                  refund for unused portion of the Monthly Plan. The balance in
                  your E-Wallet can be returned to you after settlement of all
                  orders.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReturnPolicy;
