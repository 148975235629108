import React, { Component } from "react";
class HeaderAdvertisement extends Component {
  render() {
    return (
      <div className="notice-top-bar bg-primary" data-sticky-start-at="180">
        <button
          className="hamburguer-btn hamburguer-btn-light notice-top-bar-close m-0 active"
          id="hello"
          data-set-active="false"
        >
          <span className="close">
            <span></span>
            <span></span>
          </span>
        </button>
        <div className="container">
          <div className="row justify-content-center py-2">
            <div className="col-9 col-md-12 text-center">
              <p className="text-color-light font-weight-semibold mb-0">
                Get Up to <strong>40% OFF</strong> New-Season Styles{" "}
                <a
                  href="#"
                  className="btn btn-primary-scale-2 btn-modern btn-px-2 btn-py-1 ml-2"
                >
                  MEN
                </a>{" "}
                <a
                  href="#"
                  className="btn btn-primary-scale-2 btn-modern btn-px-2 btn-py-1 ml-1 mr-2"
                >
                  WOMAN
                </a>{" "}
                <span className="opacity-6 text-1">* Limited time only.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderAdvertisement;
