import React from "react";
import Arrow from "../img/Arrow-Icon.png";
export const ScrollToTop = () => {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="scrollToTop" style={{ cursor: "pointer" }}>
      <img src={Arrow} onClick={handleTop} width="100px" height="100px" />
    </div>
  );
};
