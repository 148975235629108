import React, { useState, useEffect } from "react";
import jwt_decoder from "jwt-decode";
import User from "../global/User";
const VerifyLoginToken = ({ match, history }) => {
  const jwttoken = match.params.token;

  useEffect(() => {
    try {
      let decoder = jwt_decoder(jwttoken);
      if (decoder && decoder.email) {
        const tobeSavedLocal = { ...decoder, token: jwttoken };
        User.setUser(JSON.stringify(tobeSavedLocal));
      }
    } catch (err) {

      
      console.log(err);
    } finally {
      window.location.href = localStorage.getItem("lref") || "/";
    }
  });

  return <div>Please wait...</div>;
};

export default VerifyLoginToken;
