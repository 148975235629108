import React, { Component } from "react";
import User from "../../global/User";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class CheckoutCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
    };
    this.applyCoupon = this.applyCoupon.bind(this);
  }
  applyCoupon() {
    this.applyCouponCode();
  }
  applyCouponCode() {
    let user = User.getUser();
    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() +
          "/sscapi/web/apply-discountcode",
        {
          key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
          token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
          merchantId: user.id,
          source: "web",
          code: this.state.couponCode,
        }
      )
      .then((res) => {
        if (res.data.Response.status !== 200) {
          return toast.error(res.data.Response.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.props.applycouponCode({ couponCode: this.state.couponCode });
      })
      .catch((err) => {
        console.log("Error in Single Product APi", err);
      });
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <p>
              Have a coupon?{" "}
              <a
                href="#"
                className="text-color-dark text-color-hover-primary text-uppercase text-decoration-none font-weight-bold collapsed"
                data-toggle="collapse"
                data-target=".coupon-form-wrapper"
                aria-expanded="false"
              >
                Enter your code
              </a>
            </p>
          </div>
        </div>

        <div className="row coupon-form-wrapper mb-5 collapse" style={{}}>
          <div className="col">
            <div className="card border-width-3 border-radius-0 border-color-hover-dark">
              <div className="card-body">
                <form role="form" method="post" action={""}>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control h-auto border-radius-0 line-height-1 py-3"
                      name="couponCode"
                      placeholder="Coupon Code"
                      onInput={(e) => {
                        this.setState({ couponCode: e.target.value });
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 ws-nowrap btn-px-4 py-3 ml-2"
                      onClick={(e) => {
                        this.applyCoupon(e);
                      }}
                    >
                      Apply Coupon
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
