import React, { Component } from "react";
export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bA: {},
      sA: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    let bA = nextProps.customer;
    this.setState({ bA });
    if (bA.sa) {
      this.setState({ sA: bA.sa });
      delete bA.sa;
      return false;
    }
    this.setState({ sA: bA });
  }
  render() {
    return (
      <div className="row pt-3">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <h2 className="text-color-dark font-weight-bold text-5-6 mb-1">
            Billing Address
          </h2>
          <ul className="list list-unstyled text-2 mb-0">
            <li className="mb-0">
              {this.state.bA?.default_address?.firstName +
              " " +
              this.state.bA?.default_address?.lastName
                ? ""
                : ""}
            </li>
            <li className="mb-0">
              {this.state.bA?.default_address?.address1 ?? ""}
            </li>
            <li className="mb-0">
              {this.state.bA?.default_address?.address2 ?? ""}
            </li>
            <li className="mb-0">
              {this.state.bA?.default_address?.contact_number ??
                this.state.bA?.default_address?.phone ??
                ""}
            </li>
            <li className="mt-3 mb-0">{this.state.bA?.email ?? ""}</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <h2 className="text-color-dark font-weight-bold text-5-6 mb-1">
            Shipping Address
          </h2>
          <ul className="list list-unstyled text-2 mb-0">
            <li className="mb-0">
              {this.state.sA?.default_address?.firstName +
              " " +
              this.state.sA?.default_address?.lastName
                ? ""
                : ""}
            </li>
            <li className="mb-0">
              {this.state.sA?.default_address?.address1 ?? ""}
            </li>
            <li className="mb-0">
              {this.state.sA?.default_address?.address2 ?? ""}
            </li>
            <li className="mb-0">
              {this.state.sA?.default_address?.contact_number ??
                this.state.sA?.default_address?.phone ??
                ""}
            </li>
            <li className="mt-3 mb-0">{this.state.sA?.email ?? ""}</li>
          </ul>
        </div>
      </div>
    );
  }
}
