import React, { Component } from "react";
import User from "../../global/User";

export default class CheckoutLogin extends Component {
  render() {
    return (
      <div>
        {!User.getUser() && (
          <div className="row">
            <div className="col">
              <p className="mb-1">
                Returning customer?{" "}
                <a
                  href={`${process.env.REACT_APP_NODE_API_URL}/login-merchant?redirecturl=${window.origin}/verify-login-merchant`}
                  className="text-color-dark text-color-hover-primary text-uppercase text-decoration-none font-weight-bold collapsed"
                  data-toggle="collapse"
                  data-target=".login-form-wrapper"
                  aria-expanded="false"
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
