import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
class HeaderNavbar extends Component {
  render() {
    return (
      <div>
        <div className="header-nav-bar sticky-navbar"  >
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column">
                <div className="header-row justify-content-end">
                  <div
                    className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border justify-content-start"
                    data-sticky-header-style="{'minResolution': 991}"
                    data-sticky-header-style-active="{'margin-left': '120px'}"
                    data-sticky-header-style-deactive="{'margin-left': '0'}"
                  >
                    <div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1 w-100">
                      <nav >
                        <ul className="nav nav-pills w-100" id="mainNav" >
                          <li className="dropdown" key={"8"} >
                            <NavLink
                              className="dropdown-item dropdown-toggle"
                              to={"/all"}
                              exact
                              onClick={(e) => e.preventDefault()}
                              style={{color:"#fff" }}>
                              ALL
                              <i className="fas fa-chevron-down"></i>
                            </NavLink>
                            <ul className="dropdown-menu">
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/mobile-computers"
                                  
                                >
                                 MOBILE & COMPUTERS
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/beauty-grooming"
                                  
                                >
                                  BEAUTY GROOMING
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/home-kitchen"
                                  
                                >
                                  {" "}
                                  HOME & KITCHEN
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/jewellery"
                                  
                                >
                                  {" "}
                                  JEWELLERY
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/home-improvement"
                                  
                                >
                                  {" "}
                                  HOME IMPROVEMENT
                                </Link>
                             
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/clothing-accessories"
                                  
                                >
                                  {" "}
                                  CLOTHING ACCESSORIES
                                </Link>
                                
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/sports-fitness"
                                >
                                  {" "}
                                  SPORTS FITNESS
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/categories/automobile-accessories"
                                >
                                  {" "}
                                  AUTOMOBILE ACCESSORIES
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link className="dropdown-item" to="/gifts">
                                  {" "}
                                 GIFTS
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link className="dropdown-item" to="/footwear">
                                  {" "}
                                  FOOTWEAR
                                </Link>
                              </li>
                              <li className="dropdown-submenu" style={{color:"#fff" }}>
                                <Link
                                  className="dropdown-item"
                                  to="/photography-accessories"
                                >
                                  {" "}
                                  PHOTOGRAPHY ACCESSORIES
                                </Link>
                              </li>
                            </ul>
                          </li>
                     
                          <li className="dropdown dropdown-mega" >
                            <Link to="/categories/mobile-computers" style={{color:"#fff" }}>
                              MOBILE & COMPUTERS
                            </Link>

                            
                          </li>
                          <li className="dropdown">
                            <Link to="/categories/beauty-grooming" style={{color:"#fff" }}>
                              {" "}
                              BEAUTY GROOMING
                            </Link>

                            </li>
                          <li className="dropdown">
                            <Link to="/categories/home-kitchen" style={{color:"#fff" }}>
                              {" "}
                              HOME & KITCHEN
                            </Link>

                          
                          </li>
                          <li className="dropdown">
                            <Link to="/categories/jewellery" style={{color:"#fff" }}> JEWELLERY</Link>

                             
                          </li>
                          <li className="dropdown">
                            <Link to="/categories/home-improvement" style={{color:"#fff" }}>
                              {" "}
                              HOME IMPROVEMENT
                            </Link>                    
                          </li>
                          <li className="dropdown">
                            <Link to="/categories/clothing-accessories" style={{color:"#fff" }}>
                              {" "}
                              CLOTHING ACCESSORIES
                            </Link>

                          
                          </li>
                          <li className="dropdown" >
                            <Link to="/categories/sports-fitness" style={{color:"#fff" }}>
                              {" "}
                              SPORTS FITNESS
                            </Link>
                          </li>         
                        </ul>
                      </nav>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderNavbar;
