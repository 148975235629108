import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

class FeaturedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsList: [],
      keyword: props.keyword ?? "footwear",
      headingText: props.headingText ?? "New Products",
    };
  }
  componentDidMount() {
    this.productListHomeWidget();
  }

  productListHomeWidget() {
    let params = {
      keyword: this.state.keyword,
      type: 1,
      limit: 3,
      pageNumber: 1,
      source: "web",
    };

    axios
      .post(
        process.env.REACT_APP_NODE_API_URL.trim() + "/shopperrb2b/solr/toprated",
        params
      )
      .then((res) => {
        this.setState({ productsList: res.data.Response.data.products });
      });
  }

  render() {
    return (
      <div className="col-md-6 col-lg-3">
        <h4 className="font-weight-semibold text-4 mb-3">
          {this.state.headingText}
        </h4>

        {this.state.productsList
          ? this.state.productsList.map((item, index) => {
              return (
                <div
                  className="product row row-gutter-sm align-items-center mb-4"
                  style={{ marginTop: "25px" }}
                  key={index}
                >
                  <div className="col-5 col-md-12 col-lg-5">
                    <div className="product-thumb-info border-0">
                      <NavLink
                        className="product-desc text-color-dark text-color-hover-primary"
                        to={"/product/" + item.url}
                        exact
                      >
                        <div className="product-thumb-info-image">
                          <img
                            alt=""
                            className="img-fluid"
                            src={
                              item.imgUrl && item.imgUrl.length
                                ? item.imgUrl[0].src
                                : ""
                            }
                          />
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-7 col-md-12 col-lg-7 ms-md-0 ms-lg-0 ps-lg-1 pt-1">
                    <NavLink
                      className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-2"
                      to={"/" + item.category}
                      exact
                    >
                      {item.category}
                    </NavLink>
                    <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                      <NavLink
                        className="product-desc text-color-dark text-color-hover-primary"
                        to={"/product/" + item.url}
                        exact
                      >
                        {item.title.substring(0, 35)}...
                      </NavLink>
                    </h3>
                    <div title="Rated 5 out of 5">
                      <input
                        type="text"
                        className="d-none"
                        defaultValue="5"
                        title=""
                        data-plugin-star-rating=""
                        data-plugin-options="{'displayOnly': true, 'color': 'dark', 'size':'xs'}"
                      />
                    </div>
                    <p className="price text-4 mb-0">
                      <span className="sale text-color-dark font-weight-semi-bold">
                        ₹ {item.displayPrice}
                      </span>
                      <span className="amount">
                        ₹ {item.recommendedRetailPrice}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

export default FeaturedProducts;
