import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [
        0,
        parseInt(
          this.props.stats && this.props.stats.max ? this.props.stats.max : 1000
        ),
      ],
      min: this.props.stats && this.props.stats.min ? this.props.stats.min : 0,
      max:
        this.props.stats && this.props.stats.max ? this.props.stats.max : 1000,
    };
  }

  classes = makeStyles({
    root: {
      width: 300,
    },
  });
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleChangeCommitted = (event, newValue) => {
    this.props.parentCallback({ price: newValue });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.price &&
      nextProps.price[0] !== this.state.min[0] &&
      nextProps.price[1] !== this.state.max[1]
    ) {
      this.setState({ min: nextProps.price[0] });
      this.setState({ max: nextProps.price[1] });
    }
  }

  valuetext(value) {
    return `${value}`;
  }
  render() {
    return (
      <div className={this.classes.root}>
        <Typography id="range-slider" gutterBottom>
          PRICE
        </Typography>
        <Slider
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
          max={this.state.max}
          min={this.state.min}
          onChangeCommitted={this.handleChangeCommitted.bind(this)}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={this.valuetext}
          style={{ width: "calc(100% - 10px)", marginLeft: "10px" }}
        />
        <div style={{ display: "inline" }}>
          <span style={{ margin: "6%" }}>
            <b>MIN : </b> {this.state.value[0]}
          </span>
          <span style={{ margin: "6%" }}>
            <b>MAX : </b> {this.state.value[1]}
          </span>
        </div>
      </div>
    );
  }
}

export default SearchFilter;
