import React, { Component } from "react";
import MetaTags from "react-meta-tags";

class DeliveryAndShipping extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Delivery And Shipping</title>
          <meta
            id="description"
            name="mg-description"
            content="Delivery And Shipping In Shopperr."
          />
          <meta
            id="keywords"
            property="mg-keyword"
            content="head,body,html,tags"
          />
        </MetaTags>
        <div className="container">
          {" "}
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="font-weight-normal text-7 mb-2"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                <strong className="font-weight-extra-bold">
                  Delivery And Shipping
                </strong>
              </h2>
              <strong>Delivery and shipping policy</strong>
              <p>
                Shopperr and its distributors/suppliers reserve the right to
                restrict shipments to within India. All orders must be placed
                with a residential ship-to address. Orders with P.O. Box, APO,
                or FPO shipping addresses will not be processed. Any orders with
                shipping addresses outside India will not be processed.
                Individual suppliers may have additional shipping restrictions.
                However, in no way does the absence of a specific shipping
                policy by a supplier imply that the supplier offers any of the
                above mentioned services.
              </p>

              <strong>Shipping and handling charges</strong>

              <p>
                You are responsible for these charges for shipments that are
                refused or returned for any non-supplier caused. We are not
                responsible for incidental or consequential damages or losses to
                defective products, errors in shipping your order, or other
                errors. The limit of our liability is the replacement cost of
                any item you purchase from us. ANY ODD SIZED OR EXCESSIVE
                WEIGHTED ITEMS MAY REQUIRE A CUSTOM SHIPPING COST CALCULATION
                FROM Shopperr. ON THESE OCCASIONS, THE ORDERS WILL NOT SHIP
                UNTIL YOU HAVE APPROVED THE CUSTOM SHIPPING COST. Depending on
                information provided by the supplier, Shopperr may publish the
                shipping weights of items. The publication, or absence of,
                shipping weights cannot be used to determine ship cost and/or
                your financial obligation. The ‘Ship Cost’ provided on every
                product page and in the shopping cart should solely be used for
                determining the ship cost.
              </p>
              <div className="sticky-container">
                <div className="row">
                  <div className="col-lg-8">
                    <strong>Order placement</strong>

                    <p>
                      Shopperr is not responsible for loss, or damages as a
                      result of misplaced, mis-ordered or other typographical
                      errors that occur during order placement. The members has
                      sole responsibility for verifying the accuracy of the
                      order, including actual item, pricing, shipping, and/or
                      handling, prior to order submission. Order placement means
                      (single, batch or API) does not change member
                      responsibility and subsequent financial responsibility.
                    </p>
                    <strong>Order changes</strong>

                    <p>
                      Generally, once an order is placed, it cannot be changed
                      in any way. However, under certain circumstances, orders
                      can be canceled and/or modified. If the Member contacts
                      Shopperr’s customer service department using the online
                      form as soon as possible with a change request, it may be
                      occasionally possible to amend the order. Shopperr is not
                      responsible for any loss, or damages that are a result of
                      a change request occurring after the order has been
                      placed.
                    </p>
                  </div>
                </div>
              </div>
              <strong>Order returns</strong>

              <p>Refunds can be issued for the following cases:</p>
              <ul style={{ color: "#252129" }}>
                <li>
                  Received wrong item or wrong color, size, or model, etc. We
                  need evidence.
                </li>
                <li>Received in damaged condition. We need evidence.</li>
                <li>
                  Item is defective or malfunction. We will verify proper
                  usage/handling.
                </li>
                <li>
                  Item is extremely poor quality. We need evidence within 5 days
                  of receipt. Note that we do not refund if due to minor flaws
                  caused during production or shipping process.
                </li>
                <li>
                  Insufficient quantity. We will either partial refund or reship
                  missing quantity.
                </li>
                <li>Order is extremely late or lost.</li>
                <li>
                  Ordered wrong item or no longer needs the item. We need
                  evidence that the returned item has reached our warehouse. We
                  will partial refund 80% of the item price and retain 20% as
                  restocking fee.
                </li>
              </ul>

              <p>
                <strong> Note</strong>: Generally, you have the option of either
                refund or reship.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryAndShipping;
