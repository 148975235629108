import React, { Component } from "react";
import cf from "../../global/CommonFunctions";

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ order: nextProps.order });
  }
  render() {
    return (
      <div className="card border-width-3 border-radius-0 border-color-hover-dark mb-4">
        <div className="card-body">
          <h4 className="font-weight-bold text-uppercase text-4 mb-3">
            Your Order
          </h4>
          <table className="shop_table cart-totals mb-0">
            <tbody>
              <tr>
                <td colSpan={2} className="border-top-0">
                  <strong className="text-color-dark">Product</strong>
                </td>
              </tr>
              {this.state.order &&
                this.state.order.products.map((el) => {
                  return (
                    <tr>
                      <td>
                        <strong className="d-block text-color-dark line-height-1 font-weight-semibold">
                          {el.name}
                          <span className="product-qty">x{el.quantity}</span>
                        </strong>
                        {/* <span className="text-1">COLOR BLACK</span> */}
                      </td>
                      <td className="text-right align-top">
                        <span className="amount font-weight-medium text-color-grey">
                          {cf.getPriceWithCurrency(el.price)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              <tr className="cart-subtotal">
                <td className="border-top-0">
                  <strong className="text-color-dark">Subtotal</strong>
                </td>
                <td className="border-top-0 text-right">
                  <strong>
                    <span className="amount font-weight-medium">
                      {cf.getPriceWithCurrency(
                        this.state.order?.customer?.total_price_of_products ?? 0
                      )}
                    </span>
                  </strong>
                </td>
              </tr>
              {/* <tr className="shipping">
                                <td className="border-top-0">
                                    <strong className="text-color-dark">Shipping</strong>
                                </td>
                                <td className="border-top-0 text-right">
                                    <strong>
                                        <span className="amount font-weight-medium">Free Shipping</span>
                                    </strong>
                                </td>
                            </tr> */}
              <tr className="total">
                <td>
                  <strong className="text-color-dark text-3-4">Total</strong>
                </td>
                <td className="text-right">
                  <strong className="text-color-dark">
                    <span className="amount text-color-dark text-5">
                      {cf.getPriceWithCurrency(
                        this.state.order?.customer?.price ?? 0
                      )}
                    </span>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
