import React, { useRef, useState ,Component} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import imgone from "../img/slider/2_1_1920xSlider1.png";
import imgtwo from "../img/slider/2_1_1920xSlider2.png";
import imgthree from "../img/slider/2_3_1920xSlider3.png";
import imgFour from "../img/slider/2_4_1920xSlider4.png";
import imgFive from "../img/slider/2_5_1920xSlider5.png";
import imgSix from "../img/slider/2_6_1920xSlider6.png";
import imgSeven from "../img/slider/2_7_1920xSlider7.png";
import Slider from "react-slick";
// import required modules
import { Navigation,Pagination } from "swiper";

export default class HomePageSlider extends Component {
    render() {
      const settings = {
        dots: true,
        
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        pauseOnHover: true,
        cssEase: "linear"
      };
      return (
        <div>
          
          <Slider {...settings} >
            <div >
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgone}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgtwo}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgthree}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgFour}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgFive}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgSix}
            alt=""
          />
            </div>
            <div>
            <img width="100%"
            className="img-fluid border-radius-0"
            src={imgSeven}
            alt=""
          />
            </div>
           
          </Slider>
        </div>
      );
    }
  }
